/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useState } from "react";
import "../../js/Trending/trending.scss";
import "../../../Components/styles/dashboard.scss";
import Sidebar from "../../Layout/Sidebar";
import Header from "../../Layout/Header";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import { toast } from "react-toastify";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { useSelector, useDispatch } from "react-redux";
import { getAlbum } from "../../../store/reducers/AllAlbum/action";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import axios from "axios";
import Country from "../countries/Country";
import Userlogin from "../Login/Login";
import UserSignup from "../Login/Signup";
import Forgotpassword from "../Login/Forgotpassword";
import avatar from "@/Assets/Images/profile.png";

export default function FreshAlbums() {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allPlaylist, setAllPlaylist] = useState([]);
  const [forgotModal, setForgotModal] = useState(false);
  const route = window.location.pathname;
  var playlistId = route.split("/").slice(-1)[0];
  const [loginModal, setLoginModal] = useState(false);
  const [dId, setDownloadId] = useState();
  const [signupModal, setSignupModal] = useState(false);
  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });
  const [errors, setErrors] = useState([]);
  const [Idc, setIdc] = useState();
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [signupFormData, setSignupFormData] = useState({});
  const [countryInit, setCountryInit] = useState();
  const [countryList, setCountryList] = useState([]);
  const [passwordSeen, setPasswordSeen] = useState(false);


  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };

  const history = useNavigate();
  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };

  const signupValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!signupFormData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error["email"] = "Please Enter proper Email";
    }

    if (!signupFormData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowed";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowed";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }

    setSignupErrors(error);
    return formIsValid;
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({ user: "Please check username or password" });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };

  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        setLoading(true);
        let err = [];
        await ApiPost("users/signup", data)
          .then((res) => {
            if (res?.data?.data === "Email has been already registered.") {
              err["error"] = res.data.data;
              setSignupErrors(err);
            }
            if (res.data.message === "Record Sucessfully Inserted.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setSignupModal(false);
              window.location.reload();
            } else {
              setLoading(false);
              setErrors({ user: res.msg });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignupChange = (e) => {
    let { name, value } = e.target;

    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const allAlbum = useSelector((state) => state.AllAlbum);
  const dispatch = useDispatch();
  const getCountryList = async () => {
    await axios
      .get("https://restcountries.eu/rest/v2/all")
      .then((res) => {
        setCountryList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    if (allAlbum?.length === 0 || 5) {
      getAllAlbum();
    }
    getAllPlaylist();
    getCountryList();
  }, []);
  const getAllPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setAllPlaylist(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllAlbum = async () => {
    await ApiGet(`album/get-all-album?limit=${null}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getAlbum(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAddToPlaylist = async (id) => {
    setLoading(true);
    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    await ApiPost("playlist/ad-t-playlist", data)
      .then((res) => {
        setLoading(false);
        console.log("response", res);
        if (res.data.message.includes("exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
          toast.success("Song is added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const downloadMusic = async () => {
    console.log("Downloading");
    await ApiGet(`music/download?musicId=${dId}`)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handledownload = async (url, title) => {
    console.log("object", url);
    toast.success("Your download will begin shortly");

    // console.log(e.target.href);
    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then(async (response) => {
      console.log("object");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${title}.mp3`); //or any other extension
      document.body.appendChild(link);
      await downloadMusic();
      link.click();
    });
  };
  console.log("window.location", window.location.href);

  const shareLink = (id) => {
    navigator.clipboard.writeText(`${window.location.href}song/${id}`);
    toast.success("Link Copied..");
  };

  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  // play all free songs on a list one by one from page to page 
  const handlePlayallAlbum = (record, key) => {
    if (record.isPaid) {
      if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
      } else {
        setLoginModal(true);
      }
    } else {
      dispatch(getMusicData({ playlistData: allAlbum, index: key }));
    }
  };

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24  pt-24-mobile">
                <div className="md:w-full">
                  <div className="pt-5 flex items-center pt-5-mobile">
                    <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                      <p>
                        <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                      </p>
                    </NavLink>
                    <p className="pl-3 adobe-font font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                      Fresh Albums
                    </p>
                  </div>
                </div>
              </div>
              {!allAlbum?.length && (
                <div className="dots mt-10">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="cus-grid pt-4">
                {allAlbum?.length
                  ? allAlbum?.map((record, key) => {
                    return (
                      <div
                        className="grid-items"
                        onClick={() => history(`/albumplay/${record._id}`)}
                        OnTouchStart={() => history(`/albumplay/${record._id}`)}
                      >
                        {/* <NavLink to="/music"> */}
                        <div className="singer-box">
                          <div
                            className="singer-img"
                            onClick={() => handlePlayallAlbum(record, key)}     // play all free songs on a list one by one from page to page 

                            OnTouchStart={() => handlePlayallAlbum(record, key)}      // play all free songs on a list one by one from page to page
                          >
                            <img src={record && record.image} alt="" />
                          </div>
                          <div className="singer-text p-5 mini-view-p-remove">
                            <div className="flex items-center justify-between cursor-pointer">
                              <div>
                                <p
                                  className="font-size-18 white-text-color font-normal tracking-normal mb-0"
                                  onClick={() =>
                                    handlePlayallAlbum(record, key)         // play all free songs on a list one by one from page to page 

                                  }
                                  OnTouchStart={() =>
                                    handlePlayallAlbum(record, key)}      // play all free songs on a list one by one from page to page
                                >
                                  {record.name}{" "}
                                </p>
                                <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                  {record.desc}{" "}
                                </p>
                                <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                  Price : $ {record.amount}{" "}
                                </p>
                                <p
                                  className="font-size-18 gray-text-color font-normal tracking-normal mb-0 hover:text-white"

                                >
                                  {/* {record.artistData.artistName +
                                      " " +
                                      record.artistData.realName}{" "} */}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </NavLink> */}
                      </div>
                    );
                  })
                  : ""}
              </div>
              {/* //* Playlist modal */}
              {playlistModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div
                          className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t"
                        // style={{
                        //   backgroundColor: "#090C10",
                        // }}
                        >
                          <h3 className="text-2xl text-center font-bold text-white">
                            Select Playlist
                          </h3>

                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                            OnTouchStart={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }
                          >
                            <p className="button-center-modal">
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div
                          className="relative p-6 flex-auto"
                        // style={{
                        //   backgroundColor: "#090C10",
                        // }}
                        >
                          <p className="my-4 text-white text-lg leading-relaxed">
                            <div
                              className="flex items-center flex-row flex-wrap pt-4"
                              style={{ height: "450px", overflowY: "overlay" }}
                            >
                              {allPlaylist && allPlaylist.length ? (
                                allPlaylist.map((record, index) => {
                                  return (
                                    <div
                                      className="pl-3 pr-3 cursor-pointer "
                                      key={index}
                                      onClick={() => {
                                        handleAddToPlaylist(record._id, index);
                                      }}
                                      OnTouchStart={() => {
                                        handleAddToPlaylist(record._id, index);
                                      }}
                                    >
                                      <div className="singer-box">
                                        <div className="singer-img">
                                          <img
                                            src={
                                              record &&
                                              record.Songs &&
                                              record.Songs.length &&
                                              record.Songs[0].musicImage
                                            }
                                            style={{
                                              height: "150px",
                                              width: "335px",
                                            }}
                                            alt=""
                                          />
                                        </div>
                                        <div className="singer-text p-4">
                                          <div className="flex items-center justify-between">
                                            <div>
                                              <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                                {record.playlist_name}
                                              </p>
                                            </div>
                                            <div>
                                              {record?.Songs?.map((rec) => {
                                                if (
                                                  rec._id ===
                                                  playlistModal.musicId
                                                ) {
                                                  return (
                                                    <>
                                                      <p className="cursor-pointer font-size-14 login-text-color">
                                                        added
                                                        <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                      </p>
                                                    </>
                                                  );
                                                }
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="dots">
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                  <div className="dot"></div>
                                </div>
                              )}
                            </div>
                          </p>
                        </div>
                        {/*footer*/}
                        <div className="flex flex space-x-24 items-center justify-end p-4  border-solid border-white">
                          <button className="w-full text-left">
                            <NavLink
                              className={`s-sidebar__nav-link ${route === "/create-playlist" ? "s-sidebar__nav-link-active" : ""
                                }`}
                              to="/create-playlist"
                            >
                              <div className="sidebar-img flex justify-center items-center">
                                {route === "/create-playlist" ?
                                  (
                                    <img
                                      src=""
                                      alt=""
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src=""
                                        alt=""
                                      />
                                      <span style={{ marginLeft: 100, textWrap: 'nowrap' }} className="closebtn text-white border-solid border border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                                      >
                                        Create Playlist
                                      </span>
                                    </>
                                  )}
                              </div>
                            </NavLink>
                          </button>
                          <button
                            className="closebtn text-white border-solid border border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }

                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {forgotModal ? (
                <Forgotpassword
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {loginModal ? (
                <Userlogin
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {signupModal ? (
                <UserSignup
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}
            </div>
          </div>
          {loading && <LoadingBalls />}
        </main>
      </div>
    </div>
  );
}
