/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React from "react";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";

import "../Service/service.scss";

const NewServices = () => {
  return (
    <>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex justify-center pt-5">
                <section className="pt-6">
                  <div className="container-fluid pt-12">
                    {/* <div className="md:flex">
                      <div className="md:w-full">
                        <p className="green-text-color adobe-font font-size-40 font-bold cursor-pointer tracking-normal text-center">
                          Services
                        </p>
                      </div>
                    </div> */}
                  </div>
                </section>
              </div>
            </div>
            <div className="lh_name" style={{ textAlign: "center" }} >
              <span> Join The Artists Music Group <br></br>Grow Your Network <br></br>FREE Courses, Events & More</span>
            </div>
            <div className="service-grid">
              <div className="px-2">
                <div className="service_image">
                  <iframe
                    width="200"
                    height="100"
                    src="https://www.youtube.com/embed/HWTUlXt45tM?loop=1&controls=1"
                    title="7CenturyMusic"
                  ></iframe>
                </div>
                <div className="lh_button">
                  <a
                    href="https://7thcenturymusic.app.clientclub.net/communities/groups/7th-century-music-artists/learning"
                    target="_blank" rel="noreferrer"
                  >
                    <button>Visit</button><br></br>.
                  </a>
                </div>
              </div>
              {/* <div>
                <img src={LearningHub} alt="LearningHub" />
              </div> */}
              <div className="px-2">
                <iframe
                  style={{ overflow: "hidden" }}
                  src="https://7thcenturymusic.app.clientclub.net/communities/groups/7th-century-music-artists/learning"
                  title="7CenturyMusic"
                ></iframe>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default NewServices;
