/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Dropdown } from "react-bootstrap";
import * as authUtil from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import "../../js/Trending/trending.scss";
import "../../../Components/styles/dashboard.scss";
import Sidebar from "../../Layout/Sidebar";
import Header from "../../Layout/Header";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Payments from "../Payments/Payments";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import { useSelector, useDispatch } from "react-redux";
import { getMusicByCountry } from "../../../store/reducers/MusicByCountry/action";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { Tooltip } from "@material-ui/core";
import Country from "../countries/Country";
import * as userUtils from "../../../utils/user.util";
import Userlogin from "../Login/Login";
import UserSignup from "../Login/Signup";
import Forgotpassword from "../Login/Forgotpassword";
import useOnClickOutside from "../../../helpers/outsideClick";
import AllMusicByCountry from "./AllMusicByCountry";

const stripePromise = loadStripe(`${process.env.REACT_APP_PK_LIVE}`);

const MusicByMood = (props) => {
  const userId = userUtils.getUserInfo()?.user?.id;
  const [forgotModal, setForgotModal] = useState(false);
  const route = window.location.pathname;
  var musicId = route.split("/").slice(-1)[0];
  const dispatch = useDispatch();
  const [dId, setDownloadId] = useState();
  const location = useLocation();
  const [Idc, setIdc] = useState();
  var countryName = location?.state?.recordName;
  const [loading, setLoading] = useState(false);
  const musicByCountry = useSelector((state) => state.MusicByCountry);
  const [show, setShow] = useState(false);  // this is the definition of onmouseEnter and onMouseLeave
  const getAllMusicByCountry = async () => {
    setLoading(true);
    await ApiGet("music/get-all-country-music")
      .then((res) => {
        setLoading(false);
        console.log("1234567890", res);
        if (res.status.data !== "Record list is empty") {
          console.log(res?.data?.data);
          dispatch(getMusicByCountry(res?.data?.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getCountryList = async () => {
    await axios
      .get("https://restcountries.com/v2/all")
      .then((res) => {
        setCountryList(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCountryList();
    if (Object.keys(musicByCountry).length === 0) {
      getAllMusicByCountry();
    }
    getByUserPlaylist();
  }, []);
  const musicList = musicByCountry?.[countryName];
  const [userPlaylist, setUserPlaylist] = useState([]);
  const [showModal, setShowModal] = useState({
    open: false,
    musicId: null,
    amount: null,
    musicTitle: null,
    artistId: null,
  });
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [selectionBox, setSelectionBox] = useState(true);
  const [formBox, setFormBox] = useState(false);
  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });
  const [errors, setErrors] = useState([]);
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [signupFormData, setSignupFormData] = useState({});
  const [countryInit, setCountryInit] = useState();
  const [countryList, setCountryList] = useState([]);
  const [passwordSeen, setPasswordSeen] = useState(false);
  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };

  const history = useNavigate();
  const [selectedSongIndex, setSelectedSongIndex] = useState();

  const validate = () => {
    let error = [];
    let formIsValid = true;

    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };
  const signupValidate = () => {
    let error = [];
    let formIsValid = true;

    if (!signupFormData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error["email"] = "Please Enter proper Email";
    }

    if (!signupFormData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }

    setSignupErrors(error);
    return formIsValid;
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({
                user: "Please check username or password",
              });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        setLoading(true);
        let err = [];
        await ApiPost("users/signup", data)
          .then((res) => {
            if (res?.data?.data === "Email has been already registered.") {
              setSignupErrors(err);
            }
            if (res.data.message === "Record Sucessfully Inserted.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setSignupModal(false);
              window.location.reload();
            } else {
              setLoading(false);
              setErrors({ user: res.msg });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignupChange = (e) => {
    let { name, value } = e.target;

    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getByUserPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setUserPlaylist(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleAddToPlaylist = async (id) => {
    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    setLoading(true);
    await ApiPost("playlist/add-to-playlist", data)
      .then((res) => {
        setLoading(false);
        if (res.data.message.includes("exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            // closeOnTouchStart: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
          toast.success("Song is added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const downloadMusic = async (id) => {
    console.log("Downloading");
    await ApiGet(`music/download?musicId=${id}`)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleSendEmail = async (id) => {
    const bodyId = {
      musicId: id,
      userId: userId,
    };
    await ApiPost(`downloadUser/downloadUsers`, bodyId)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handledownload = async (data) => {
    let url = data.musicUrl;
    let title = data?.musicTitle ? data?.musicTitle : data?.title;
    console.log("object", url);
    toast.success("Your download will begin shortly");

    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then(async (response) => {
      handleSendEmail(data._id);
      console.log("object");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${title}.mp3`); //or any other extension
      document.body.appendChild(link);
      downloadUserList(data);

      await downloadMusic(data._id);
      link.click();
    });
  };

  const downloadUserList = async (data) => {
    const body = {
      musicId: data._id,
      userId: userId,
      artistId: data.artistId._id,
    };

    await ApiPost(
      `musicDownloadListForArtist/create-musicDownloadListForArtist`,
      body
    )
      .then((res) => {
        console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxx", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  console.log("window.location", window.location.href);

  const shareLink = (id) => {
    navigator.clipboard.writeText(`${window.location.href}song/${id}`);
    toast.success("Link Copied..");
  };

  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  // play all free songs on a list one by one from page to page 
  const handlePlayAllMusicByCountry = (record, key) => {
    if (record.isPaid) {
      if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
      } else {
        setLoginModal(true);
      }
    } else {
      dispatch(getMusicData({ playlistData: musicList, index: key }));
    }
  };

  const [open, setOpen] = useState({});
  const NavigatorRef2 = useRef();

  useOnClickOutside(NavigatorRef2, () => {
    setOpen({});
  });

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24">
                <div className="md:w-full">
                  <div className="pt-5 flex items-center">
                    <NavLink to="/" onClick={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}>
                      <p>
                        <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                      </p>
                    </NavLink>
                    <p className="pl-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                      {countryName}
                    </p>
                  </div>
                </div>
              </div>
              {!musicList?.length && (
                <div className="dots mt-10">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="cus-grid-one pt-15">
                {musicList?.length
                  ? musicList
                    ?.slice(0, 20)
                    //?.reverse()
                    ?.map((record, index) => {
                      console.log("1-1-1-1-1-1-1-1-1-1-1", record);
                      return (
                        <div
                          key={index}
                          className="grid-items"
                        >
                          <div className="card-holder">
                            <div className="singer-box ">
                              <div
                                className="singer-img"
                                onClick={() =>
                                  record.isPaid
                                    ? authUtil.isLoggedIn()
                                      ? userUtil
                                        ?.getMusicData()
                                        ?.includes(record._id)
                                        ? dispatch(getMusicData({ playlistData: musicList, index: index }))
                                        : setShowModal({
                                          musicId: record._id,
                                          open: true,
                                          amount: record.amount,
                                          musicTitle: record.musicTitle,
                                          artistId: record._id,
                                        })
                                      : setLoginModal(true)
                                    // : dispatch(getMusicData(record, index))
                                    : dispatch(getMusicData({ playlistData: musicList, index: index }))
                                }

                              >
                                <img
                                  src={record?.musicImage}
                                  className="object-cover"
                                  alt=""
                                />
                              </div>
                              <div className="singer-text p-5">
                                <div className="flex items-center justify-between cursor-pointer">
                                  <div>
                                    <p
                                      className="font-size-18 white-text-color font-normal tracking-normal mb-0"
                                      onClick={() =>
                                        handlePlayAllMusicByCountry(record, index)}        // play all free songs on a list one by one from page to page                                                    

                                    >
                                      {record.musicTitle}
                                    </p>
                                  </div>
                                  <div className="My_Dropdown ">
                                    {(() => {
                                      if (authUtil.isLoggedIn()) {
                                        return record.isPaid ? (
                                          userUtil
                                            ?.getMusicData()
                                            ?.includes(record._id) ? (
                                            <Dropdown show={index === selectedSongIndex} //Code ya leo
                                              onMouseEnter={() => {
                                                setSelectedSongIndex(index)
                                              }}
                                              onMouseLeave={() => {
                                                setSelectedSongIndex()
                                              }}
                                            >
                                              <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic"
                                                className="text-white"
                                              >
                                                <Tooltip title="More" arrow>
                                                  <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                                                </Tooltip>
                                              </Dropdown.Toggle>
                                              {index === selectedSongIndex && (<Dropdown.Menu>
                                                <div
                                                  className="flex flex-col w-32 max-w-full box-shadow-cus border-color"
                                                  style={{
                                                    backgroundColor: "#090C10",
                                                  }}
                                                >
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      authUtil.isLoggedIn()
                                                        ? setPlaylistModal({
                                                          musicId: record._id,
                                                          open: true,
                                                        })
                                                        : setLoginModal(true)
                                                    }

                                                  >
                                                    Add to playlist
                                                  </div>

                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      dispatch(getMusicData(record))
                                                    }

                                                  >
                                                    Play song now
                                                  </div>
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      history(`/song/${record._id}`)
                                                    }

                                                  >
                                                    Details
                                                  </div>
                                                  {!record.isPaid && (
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() => {
                                                        setDownloadId(record._id);
                                                        console.log(
                                                          "Download Onclick",
                                                          record._id
                                                        );
                                                        authUtil.isLoggedIn()
                                                          ? handledownload(record)
                                                          : setLoginModal(true);
                                                      }}

                                                    >
                                                      Download
                                                    </div>
                                                  )}
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() => {
                                                      shareLink(record._id);
                                                    }}

                                                  >
                                                    <button onClick={copy}
                                                    >
                                                      {!copied
                                                        ? "Copy link"
                                                        : "Copy link"}
                                                    </button>
                                                  </div>
                                                </div>
                                              </Dropdown.Menu>)}
                                            </Dropdown>
                                          ) : (
                                            <div
                                              onClick={() =>
                                                handlePlayAllMusicByCountry(record, index)         // play all free songs on a list one by one from page to page 
                                              }
                                            >
                                              <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                            </div>
                                          )
                                        ) : (
                                          <Dropdown show={index === selectedSongIndex} //Code ya leo
                                            onMouseEnter={() => {
                                              setSelectedSongIndex(index)
                                            }}
                                            onMouseLeave={() => {
                                              setSelectedSongIndex()
                                            }}
                                          >
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                              className="text-white"
                                            >
                                              <Tooltip title="More" arrow>
                                                <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                                              </Tooltip>{" "}
                                            </Dropdown.Toggle>

                                            {index === selectedSongIndex && (<Dropdown.Menu>
                                              <div
                                                className="flex flex-col w-32 max-w-full box-shadow-cus border-color"
                                                style={{
                                                  backgroundColor: "#090C10",
                                                }}
                                              >
                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() =>
                                                    authUtil.isLoggedIn()
                                                      ? setPlaylistModal({
                                                        musicId: record._id,
                                                        open: true,
                                                      })
                                                      : setLoginModal(true)
                                                  }

                                                >
                                                  Add to playlist
                                                </div>

                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() =>
                                                    dispatch(getMusicData(record))
                                                  }

                                                >
                                                  Play song now
                                                </div>
                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() =>
                                                    history(`/song/${record._id}`)
                                                  }

                                                >
                                                  Details
                                                </div>
                                                {!record.isPaid && (
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() => {
                                                      setDownloadId(record._id);
                                                      console.log(
                                                        "Download Onclick",
                                                        record._id
                                                      );
                                                      authUtil.isLoggedIn()
                                                        ? handledownload(record)
                                                        : setLoginModal(true);
                                                    }}

                                                  >
                                                    Download
                                                  </div>
                                                )}
                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() => {
                                                    shareLink(record._id);
                                                  }}

                                                >
                                                  <button onClick={copy}
                                                  >
                                                    {!copied
                                                      ? "Copy link"
                                                      : "Copy link"}
                                                  </button>
                                                </div>
                                              </div>
                                            </Dropdown.Menu>)}
                                          </Dropdown>
                                        );
                                      }
                                      else {
                                        // (authUtil.isLoggedIn())  // problem is that if this line is uncommented no dropdown will display while user is not ;ogged in
                                        return record.istPaid ? (
                                          userUtil
                                            ?.getMusicData()
                                            ?.includes(record._id) ? (
                                            <Dropdown show={index === selectedSongIndex} //Code ya leo
                                              onMouseEnter={() => {
                                                setSelectedSongIndex(index)
                                              }}
                                              onMouseLeave={() => {
                                                setSelectedSongIndex()
                                              }}
                                            >
                                              <Dropdown.Toggle
                                                variant="success"
                                                id="dropdown-basic"
                                                className="text-white"
                                              >
                                                <Tooltip title="More" arrow>
                                                  <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                                                </Tooltip>
                                              </Dropdown.Toggle>
                                              {index === selectedSongIndex && (<Dropdown.Menu>
                                                <div
                                                  className="flex flex-col w-32 max-w-full box-shadow-cus border-color"
                                                  style={{
                                                    backgroundColor: "#090C10",
                                                  }}
                                                >
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      authUtil.isLoggedIn()
                                                        ? setPlaylistModal({
                                                          musicId: record._id,
                                                          open: true,
                                                        })
                                                        : setLoginModal(true)
                                                    }

                                                  >
                                                    Add to playlist
                                                  </div>

                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      dispatch(getMusicData(record))
                                                    }

                                                  >
                                                    Play song now
                                                  </div>
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      history(`/song/${record._id}`)
                                                    }

                                                  >
                                                    Details
                                                  </div>
                                                  {!record.isPaid && (
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() => {
                                                        setDownloadId(record._id);
                                                        console.log(
                                                          "Download Onclick",
                                                          record._id
                                                        );
                                                        authUtil.isLoggedIn()
                                                          ? handledownload(record)
                                                          : setLoginModal(true);
                                                      }}

                                                    >
                                                      Download
                                                    </div>
                                                  )}
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() => {
                                                      shareLink(record._id);
                                                    }}

                                                  >
                                                    <button onClick={copy}
                                                    >
                                                      {!copied
                                                        ? "Copy link"
                                                        : "Copy link"}
                                                    </button>
                                                  </div>
                                                </div>
                                              </Dropdown.Menu>)}
                                            </Dropdown>
                                          ) : (
                                            <div
                                              onClick={() =>
                                                handlePlayAllMusicByCountry(record, index)         // play all free songs on a list one by one from page to page 

                                              }
                                            >
                                              <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                            </div>
                                          )
                                        ) : (
                                          <Dropdown show={index === selectedSongIndex} //Code ya le 20 September 2023
                                            onMouseEnter={() => {
                                              setSelectedSongIndex(index)
                                            }}
                                            onMouseLeave={() => {
                                              setSelectedSongIndex()
                                            }}
                                          >
                                            <Dropdown.Toggle
                                              variant="success"
                                              id="dropdown-basic"
                                              className="text-white"
                                            >
                                              <Tooltip title="More" arrow>
                                                <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                                              </Tooltip>{" "}
                                            </Dropdown.Toggle>

                                            {index === selectedSongIndex && (<Dropdown.Menu>
                                              <div
                                                className="flex flex-col w-32 max-w-full box-shadow-cus border-color"
                                                style={{
                                                  backgroundColor: "#090C10",
                                                }}
                                              >
                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() =>
                                                    authUtil.isLoggedIn()
                                                      ? setPlaylistModal({
                                                        musicId: record._id,
                                                        open: true,
                                                      })
                                                      : setLoginModal(true)
                                                  }

                                                >
                                                  Add to playlist
                                                </div>

                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() =>
                                                    dispatch(getMusicData(record))
                                                  }
                                                >
                                                  Play song now
                                                </div>
                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() =>
                                                    history(`/song/${record._id}`)
                                                  }

                                                >
                                                  Details
                                                </div>
                                                {!record.isPaid && (
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() => {
                                                      setDownloadId(record._id);
                                                      console.log(
                                                        "Download Onclick",
                                                        record._id
                                                      );
                                                      authUtil.isLoggedIn()
                                                        ? handledownload(record)
                                                        : setLoginModal(true);
                                                    }}

                                                  >
                                                    Download
                                                  </div>
                                                )}
                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() => {
                                                    shareLink(record._id);
                                                  }}

                                                >
                                                  <button onClick={copy}
                                                  >
                                                    {!copied
                                                      ? "Copy link"
                                                      : "Copy link"}
                                                  </button>
                                                </div>
                                              </div>
                                            </Dropdown.Menu>)}
                                          </Dropdown>
                                        );
                                      }
                                    }
                                    )()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
          {/* //* Playlist modal */}
          {playlistModal.open ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div
                      className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t"

                    >
                      <h3 className="text-2xl text-center font-bold text-white">
                        Select Playlist
                      </h3>

                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() =>
                          setPlaylistModal({
                            ...playlistModal,
                            open: false,
                          })
                        }

                      >
                        <p className="button-center-modal">
                          <i className="fas fa-times"></i>
                        </p>
                      </button>
                    </div>
                    {/*body*/}
                    <div
                      className="relative p-6 flex-auto"
                    // style={{
                    //   backgroundColor: "#090C10",
                    // }}
                    >
                      <p className="my-4 text-white text-lg leading-relaxed">
                        <div
                          className="flex items-center flex-row flex-wrap pt-4"
                          style={{
                            height: "450px",
                            overflowY: "overlay",
                          }}
                        >
                          {userPlaylist && userPlaylist.length ? (
                            userPlaylist.map((record, index) => {
                              return (
                                <div
                                  className="pl-3 pr-3 cursor-pointer "
                                  key={index}
                                  onClick={() => {
                                    handleAddToPlaylist(record._id);
                                  }}

                                >
                                  <div className="card-holder">
                                    <div className="singer-box">
                                      <div className="singer-img">
                                        <img
                                          src={
                                            (record &&
                                              record.Songs &&
                                              record.Songs.length &&
                                              record.Songs[0].musicImage) ||
                                            `https://source.unsplash.com/1600x900/?${record.playlist_name}`
                                          }
                                          style={{
                                            height: "150px",
                                            width: "335px",
                                          }}
                                          alt=""
                                        />
                                      </div>
                                      <div className="singer-text p-4">
                                        <div className="flex items-center justify-between">
                                          <div>
                                            <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                              {record.playlist_name}
                                            </p>
                                          </div>
                                          <div>
                                            {record?.Songs?.map((rec) => {
                                              if (
                                                rec._id ===
                                                playlistModal.musicId
                                              ) {
                                                return (
                                                  <>
                                                    <p className="cursor-pointer font-size-14 login-text-color">
                                                      added
                                                      <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                    </p>
                                                  </>
                                                );
                                              }
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="dots">
                              <div className="dot"></div>
                              <div className="dot"></div>
                              <div className="dot"></div>
                            </div>
                          )}
                        </div>
                      </p>
                    </div>
                    {/*footer*/}
                    <div className="flex flex space-x-24 items-center justify-end p-4  border-solid border-white">
                      <button className="w-full text-left">
                        <NavLink
                          className={`s-sidebar__nav-link ${route === "/create-playlist" ? "s-sidebar__nav-link-active" : ""
                            }`}
                          to="/create-playlist"
                        >
                          <div className="sidebar-img flex justify-center items-center">
                            {route === "/create-playlist" ?
                              (
                                <img
                                  src=""
                                  alt=""
                                />
                              ) : (
                                <>
                                  <img
                                    src=""
                                    alt=""
                                  />
                                  <span style={{ marginLeft: 100, textWrap: 'nowrap' }} className="closebtn text-white border-solid border border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                                  >
                                    Create Playlist
                                  </span>
                                </>
                              )}
                          </div>
                        </NavLink>
                      </button>
                      <button
                        className="closebtn text-white border-solid border border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() =>
                          setPlaylistModal({
                            ...playlistModal,
                            open: false,
                          })
                        }
                        OnTouchStart={() =>
                          setPlaylistModal({
                            ...playlistModal,
                            open: false,
                          })
                        }
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}

          {/* //* Purchase modal */}
          {showModal.open ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                  {/*content*/}
                  <div className="poppop border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div
                      className="flex items-start justify-between pt-5 pr-5 pl-5 border-solid border-white-100 rounded-t-lg"
                    // style={{
                    //   backgroundColor: "#0B0B0B",
                    // }}
                    >
                      <h3 className="text-2xl font-bold text-white">
                        Buy this song or album
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() =>
                          setShowModal({
                            ...showModal,
                            open: false,
                          })
                        }

                      >
                        <p>
                          <i className="fas fa-times"></i>
                        </p>
                      </button>
                    </div>
                    {/*body*/}
                    <div
                      className="relative p-6 flex-auto rounded-b-lg"
                    // style={{
                    //   backgroundColor: "#090C10",
                    // }}
                    >
                      <p className="mb-3 text-white leading-relaxed">
                        Like this artists music? Show your support by purchasing
                        their music.
                      </p>
                      <p className="mb-3 text-white text-lg leading-relaxed">
                        Title: {showModal.musicTitle}
                      </p>
                      <p className="mb-3 text-white text-lg leading-relaxed">
                        Price: ${showModal.amount}$
                      </p>
                      <Elements stripe={stripePromise}>
                        <Payments
                          musicId={showModal.musicId}
                          amount={showModal.amount}
                          musicTitle={showModal.musicTitle}
                          musicArtistId={showModal.artistId}
                        />
                      </Elements>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}

          {forgotModal ? (
            <Forgotpassword
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}

          {loginModal ? (
            <Userlogin
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}

          {signupModal ? (
            <UserSignup
              setSignupModal={setSignupModal}
              setLoginModal={setLoginModal}
              setForgotModal={setForgotModal}
            />
          ) : null}
          {loading && <LoadingBalls />}
        </main>
        {loading && <LoadingBalls />}
      </div>
    </div>
  );
};

export default MusicByMood;
