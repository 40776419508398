/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import { useState } from "react";
import { toast } from "react-toastify";
import { ApiPost } from "../../../helpers/API/ApiData";

const Forgotpassword = (props) => {
  const [forgotChange, setforgetChange] = useState({});
  const [loading, setLoading] = useState(false);
  const [emailChange, setemailChange] = useState();
  const [role, setRole] = useState("user");
  const [emailvalid, setemailvalid] = useState();
  const handleForgot = async () => {
    if (forgotChange === "" || forgotChange === undefined) {
      console.log(forgotChange, "forgotchnage");
      setemailChange("Please Enter the Email");
      setemailvalid("");
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        forgotChange
      )
    ) {
      setemailvalid("Please enter Valid Email");
      setemailChange("");
    } else {
      const data = {
        email: forgotChange,
      };

      await ApiPost(`users/forget-password?type=${role}`, data)
        .then((res) => {
          console.log(res.data);

          if (res.data.message === "PASSWORD RESET LINK SENT SUCCESSFULLY..!") {
            toast.success(res.data.message);
            setemailChange("");
            setemailvalid("");
            props.setForgotModal(false);
          } else {
            setemailChange("");
            setemailvalid("");
            toast.error(res.data.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log("err", err);
          toast.error(err);
        });
    }
  };
  return (
    <div>
      <div className="animation justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full my-6 mx-auto lg:w-5/12 md:w-4/5 sm:w-1/2">
          <div className="relative modal-login-box border-0 rounded-3xl shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 rounded-t">
              <h3 className="text-2xl font-bold text-white"></h3>

              <button
                className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => {
                  props.setForgotModal(false);
                }}
                OnTouchStart={() => {
                  props.setForgotModal(false);
                }}
              >
                <p>
                  <i className="fas fa-times login-text-color relative"></i>
                </p>
              </button>
            </div>
            <div className="relative p-6 flex-auto end-text">
              <div>
                <div className="">
                  <div>
                    <div className="login_logo pl-5 pr-5 flex justify-center relative">
                      <img
                        src={
                          require("../../../Assets/Images/login-logo.png")
                            .default
                        }
                        alt=""
                      />
                    </div>
                    <span
                      style={{
                        color: "white",
                      }}
                    >
                      Please enter your email address. We will send you an email
                      to reset your password.
                    </span>
                  </div>
                </div>

                <p className="text-center font-size-14 pt-12">
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    {emailChange}
                    {emailvalid}
                  </span>
                </p>
                <div className="flex flex-col items-left justify-center login-placeholder pt-5">
                  <div className="">
                    <input
                      type="text"
                      name="email"
                      className="form-control-login mb-2"
                      placeholder="Enter email"
                      onChange={(e) => setforgetChange(e.target.value)}
                      // onKeyUp={(event) => handleSubmit(event)}
                    />{" "}
                    <span
                      style={{
                        color: "red",
                        top: "0px",
                        fontSize: "12px",
                      }}
                    ></span>
                  </div>
                </div>
                <div>
                  <div className="flex justify-center">
                    <button
                      className="login-modal-btn font-size-16 white-text-color font-normal tracking-normal cursor-pointer"
                      onClick={() => handleForgot()}
                      OnTouchStart={() => handleForgot()}
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                  <div>
                    <p className="font-size-18 white-text-color font-normal tracking-normal mb-0 text-center mt-10"></p>
                    <p className="font-size-16 login-text-color font-normal mt-3 mb-8 tracking-normal text-center cursor-pointer"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgotpassword;
