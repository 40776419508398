/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import * as authUtil from "../../utils/auth.util";
import * as userUtil from "../../utils/user.util";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import "../styles/dashboard.scss";
import { ApiGet, ApiPost } from "./../../helpers/API/ApiData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Payments from "./Payments/Payments";
import LoadingBalls from "../../Assets/LoadingBalls";
import { useSelector, useDispatch } from "react-redux";
import { getNewRelese as getNewRelease } from "../../store/reducers/NewRelease/action";
import { getTrending } from "../../store/reducers/AllTrending/action";
import { getFree } from "../../store/reducers/AllFree/action";
import { getAlbum } from "../../store/reducers/AllAlbum/action";
import { getArtist } from "../../store/reducers/Artists/action";
import { getPlaylist } from "../../store/reducers/AllPlaylist/action";
import { getMyMusics } from "../../store/reducers/MyMusics/action";
import { getMusicData } from "../../store/reducers/MusicData/action";
import { getGenre } from "../../store/reducers/AllGenre/action";
import { getMyPlaylist } from "../../store/reducers/AllMyPlaylist/action";
import { getMusicByCountry } from "../../store/reducers/MusicByCountry/action";
import { getVideos } from "../../store/reducers/AllVideos/action";
import axios from "axios";
import CookieConsent from "react-cookie-consent";
import { Tooltip } from "@material-ui/core";
import * as userUtils from "../../utils/user.util";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Country from "./countries/Country";
import UserSignup from "./Login/Signup";
import Userlogin from "./Login/Login";
import Menu from "../../Assets/Images/dots.png";
import Forgotpassword from "./Login/Forgotpassword";
import useOnClickOutside from "../../helpers/outsideClick";
import FastClick from 'fastclick';
import avatar from "@/Assets/Images/profile.png";
import NewRelease from "./NewRelease/NewRelease";

const stripePromise = loadStripe(`${process.env.REACT_APP_PK_LIVE}`);
const Dashboard = (props) => {
  const recaptchaRef = React.createRef();
  const route = window.location.pathname;

  const userId = userUtils.getUserInfo()?.user?.id;
  const [loading, setLoading] = useState(false);
  const [userPlaylist, setUserPlaylist] = useState([]);
  const [showModal, setShowModal] = useState({
    open: false,
    musicId: null,
    amount: null,
    musicTitle: null,
    artistId: null,
  });
  const [loginModal, setLoginModal] = useState(false);

  const [signupModal, setSignupModal] = useState(false);
  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });

  const [dId, setDownloadId] = useState();
  const [forgotModal, setForgotModal] = useState(false);

  const [dashboardImage, setDashboardImage] = useState([]);

  useEffect(() => {
    fetchDashboardImages(); // Ensure this function fetches and sets an array
       }, []);
  const fetchDashboardImages = async () => {
    try {
      const response = await ApiGet("admin/get-all-user-dashboard");
      setDashboardImage(response.data.data);
    } catch (error) {
      console.error("Error fetching dashboard images:", error);
    }
  };

  const [countries, setCountries] = useState(Country?.Country);

  useEffect(() => {
    if (Country?.Country) setCountries(Country?.Country);
  }, [Country?.Country]);

  const history = useNavigate();

  const shareLink = (id) => {
    navigator.clipboard.writeText(`${window.location.href}song/${id}`);
    toast.success("Link Copied..");
  };

  const [copied, setCopied] = useState(false);

  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  useEffect(() => {
    getByUserPlaylist();
    FastClick.attach(document.body);

  }, []);

  const newRelease = useSelector((state) => state.NewRelease);
  const allTrending = useSelector((state) => state.AllTrending);
  const allFree = useSelector((state) => state.AllFree);
  const allAlbum = useSelector((state) => state.AllAlbum);
  const allArtists = useSelector((state) => state.AllArtist);
  const allPlaylist = useSelector((state) => state.AllPlaylist);
  const allMyPlaylist = useSelector((state) => state.AllMyPlaylist);
  const allGenre = useSelector((state) => state.AllGenre);
  const myMusics = useSelector((state) => state.MyMusics);
  const musicByCountry = useSelector((state) => state.MusicByCountry);
  const allVideos = useSelector((state) => state.AllVideos);

  const dispatch = useDispatch();
  useSelector((state) => { });

  useEffect(() => {
    getDashboardImage();
    if (newRelease?.length === 0) {
      getAllNewRelease();
    }
    if (allTrending?.length === 0) {
      getAllTrending();
    }
    if (allFree?.length === 0) {
      getAllfree();
    }
    if (allAlbum?.length === 0) {
      getAllalbum();
    }
    if (myMusics?.length === 0 || myMusics === undefined) {
      getUserData();
    }
    if (allArtists?.length === 0) {
      getAllArtists();
    }
    if (allPlaylist?.length === 0) {
      getAllPlaylist();
      getAllPlayMylist();
    }
    if (allGenre?.length === 0) {
      getAllMusicMood();
    }
    if (allVideos?.length === 0) {
      getVideosList();
    }
    if (Object.keys(musicByCountry)?.length === 0) {
      getAllMusicByCountry();
    }
  }, []);

  const getVideosList = async () => {
    await ApiGet(`video/new-videos`)
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          dispatch(getVideos(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log("errr");
      });
  };

  const getAllPlaylist = async () => {
    // let limit = 5;
    let limit = window.innerWidth <= 768 ? "inf" : 5; // "inf" or any large number for infinite
    await ApiGet(`playlist/get-all-playlist/${limit}`)
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          dispatch(getPlaylist(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllPlayMylist = async () => {
    // let limit = 5;
    let limit = window.innerWidth <= 768 ? "inf" : 5; // "inf" or any large number for infinite
    await ApiGet(`playlist/get-all-playlist/${limit}`)
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          dispatch(getMyPlaylist(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });

  };
  const getByUserPlaylist = async () => {
    await ApiGet("playlist/get-by-user")
      .then((res) => {
        if (res.status.data !== "Record list is empty") {
          setUserPlaylist(res.data.data);
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllNewRelease = async () => {
    // let limit = 5;
    let limit = window.innerWidth <= 768 ? "inf" : 5; // "inf" or any large number for infinite
    await ApiGet(`music/get-all-new-release/${limit}`)
      .then((res) => {
        if (res.data.data !== "Record list is empty") {
          dispatch(getNewRelease(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllTrending = async () => {
    // let limit = 5;
    let limit = window.innerWidth <= 768 ? "inf" : 5; // "inf" or any large number for infinite
    await ApiGet(`music/get-treanding-music/${limit}`)
      .then((res) => {
        if (res.data.data !== "Record list is empty") {
          dispatch(getTrending(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllMusicByCountry = async () => {
    await ApiGet(`music/get-all-country-music`)
      .then((res) => {
        if (res?.data?.data !== "Record list is empty") {
          dispatch(getMusicByCountry(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllMusicMood = async () => {
    setLoading(true);
    // let limit = 5;
    let limit = window.innerWidth <= 768 ? "inf" : 5; // "inf" or any large number for infinite
    await ApiGet(`genre/get-all-genre/${limit}`)
      .then((res) => {
        setLoading(false);
        if (res.data.data !== "Record list is empty") {
          dispatch(getGenre(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getAllfree = async () => {
    // let limit = 5;
    let limit = window.innerWidth <= 768 ? "inf" : 5; // "inf" or any large number for infinite
    await ApiGet(`music/get-all-free-music/${limit}`)
      .then((res) => {
        if (res.data.data !== "Genre Not found") {
          dispatch(getFree(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllalbum = async () => {
    // let limit = 5;
    let limit = window.innerWidth <= 768 ? "inf" : 5; // "inf" or any large number for infinite
    await ApiGet(`album/get-all-album?limit=${limit}`)
      .then((res) => {
        if (res.data.data !== "Album Not found") {
          dispatch(getAlbum(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserData = async () => {
    console.log("User's Music");
    await ApiGet("users/get-my-profile")
      .then((res) => {
        console.log("Meet's Data", res.data.data.musicPurchased);
        dispatch(getMyMusics(res.data.data.musicPurchased));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAllArtists = async () => {
    // let limit = 6;
    let limit = window.innerWidth <= 768 ? "inf" : 6; // "inf" or any large number for infinite
    await ApiGet(`artist/get-all-artists/${limit}`)
      .then((res) => {
        if (res.data.data !== "Record list is empty") {
          console.log("artists", res.data.data);
          dispatch(getArtist(res.data.data));
        } else {
          console.log("error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDashboardImage = async () => {
    await ApiGet("admin/get-all-user-dashboard")
      .then((res) => {
        console.log("----", res.data.data);
        setDashboardImage(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddToPlaylist = async (id) => {
    const data = {
      playlistId: id,
      musicId: playlistModal.musicId,
    };
    setLoading(true);
    await ApiPost("playlist/add-to-playlist", data)
      .then((res) => {
        setLoading(false);
        if (res.data.message.includes("exists")) {
          toast.error("Song is already added to the playlist!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            closeOnTouchStart: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          setPlaylistModal({ open: false });
          toast.success("Song is added to the playlist!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              closeOnTouchStart: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,

            });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const downloadMusic = async (id) => {
    console.log("Downloading");
    await ApiGet(`music/download?musicId=${id}`)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const countryList = Object.keys(musicByCountry);

  const handleSendEmail = async (id) => {
    const bodyId = {
      musicId: id,
      userId: userId,
    };

    await ApiPost(`downloadUser/downloadUsers`, bodyId)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handledownload = async (data) => {
    let url = data?.musicUrl;
    let title = data?.musicTitle ? data?.musicTitle : data?.title;
    toast.success("Your download will begin shortly");
    setLoading(true);
    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "audio/mpeg",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(async (response) => {
        handleSendEmail(data._id);
        setLoading(false);
        console.log("Response", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.mp3`);  //or any other extension
        document.body.appendChild(link);
        link.click();
        downloadUserList(data);
        await downloadMusic(data._id);
      })
      .catch((err) => {
        setLoading(false);
        console.log("errDownload", err);
      });
  };

  const downloadUserList = async (data) => {
    console.log("-");
    const body = {
      musicId: data._id,
      userId: userId,
      artistId: data.artistData._id,
    };

    await ApiPost(
      `musicDownloadListForArtist/create-musicDownloadListForArtist`,
      body
    )
      .then((res) => {
        console.log("-");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const [open, setOpen] = useState({});
  const NavigatorRef2 = useRef();

  useOnClickOutside(NavigatorRef2, () => {
    setOpen({});
  });

  // play all free songs on a list one by one from myMusics page to FreeAlbum (and my list of music page playing with no stop
  const handlePlaymyMusics = (record, key) => {
    if (record.isPaid) {
      if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
      } else {
        setLoginModal(true);
      }
    } else {
      dispatch(getMusicData({ playlistData: myMusics, index: key }));
    }
  };

  const handlePlaynewRelease = (record, key) => {
    if (record.isPaid) {
      if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
      } else {
        setLoginModal(true);
      }
    } else {
      dispatch(getMusicData({ playlistData: newRelease, index: key }));
    }
  };

  const handlePlayallTrending = (record, key) => {
    if (record.isPaid) {
      if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
      } else {
        setLoginModal(true);
      }
    } else {
      dispatch(getMusicData({ playlistData: allTrending, index: key }));
    }
  };

  const handlePlayallFree = (record, key) => {
    if (record.isPaid) {
      if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
      } else {
        setLoginModal(true);
      }
    } else {
      dispatch(getMusicData({ playlistData: allFree, index: key }));
    }
  };

  const handlePlayallAlbum = (record, key) => {
    if (record.isPaid) {
      if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
      } else {
        setLoginModal(true);
      }
    } else {
      dispatch(getMusicData({ playlistData: allAlbum, index: key }));
    }
  };


  return (
    <div style={{ paddingBottom: "6%" }}>
      <>
        <Header />
      </>
      <div className="">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="dashboard-banner" style={{ marginTop: "100px" }}>

              {/* <img src={dashboardImage} />  */}

              <div className="lg:flex pt-4 scrolling-wrapper pt-4-mobile">
                {Array.isArray(dashboardImage) && dashboardImage?.length > 0 && 
                  dashboardImage?.map((image, index) => {
                    return (
                      <div key={index} className="lg:w-1/5 pl-3 pr-3 card">
                        <a href={image.dashboardLink} target="_blank" rel="noreferrer">
                          <div className="banner-box">
                            <div className="banner-img singer-img-hover">
                              <img
                                src={image.dashboardImage}
                                alt=""
                              />
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="container-fluid">
              {authUtil.isLoggedIn() && (
                <>
                  <div className="md:flex">
                    <div className="md:w-full pt-4 pl-3 pr-3 pl-3-mobile pr-3-mobile ">
                      <div className="flex items-center justify-between">
                        <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                          My Music
                        </p>
                        <NavLink to="/mymusics">
                          <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                            See All
                          </p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                  {!myMusics?.length && (
                    <div className="new-musics flex">No Musics Purchased</div>
                  )}
                  <div className="lg:flex pt-4 scrolling-wrapper">
                    {Array.isArray(myMusics) && myMusics?.length > 0 ?
                      myMusics
                        // ?.slice(0)
                        // ?.reverse()
                        ?.slice(0, 5)
                        // ?.filter(record => record && record.myMusics)
                        ?.map((record, index) => {
                          return (
                            <div
                              key={index}
                              className="lg:w-1/5 pl-3 pr-3 card pl-3-mobile"
                            >
                              <div className="banner-box">
                                <div
                                  className="banner-img"
                                  onClick={() =>
                                    handlePlaymyMusics(record, index)    // play all free songs on a list one by one from page to page
                                  }
                                  OnTouchStart={() =>
                                    handlePlaymyMusics(record, index)}   // play all free songs on a list one by one from page to page 
                                >
                                  <img
                                    className="object-cover"
                                    src={record?.musicImage}
                                    alt={record?.musicTitle}
                                  />
                                </div>
                                <div className="singer-text p-5">
                                  <div className="flex items-center justify-between cursor-pointer">
                                    <div className="line-nowrap">
                                      <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                        {record?.musicTitle}
                                      </p>
                                    </div>

                                    <div
                                      className="menu_dd"
                                      ref={NavigatorRef2}
                                    >
                                      {" "}
                                      <div
                                        className="menu-img"
                                        onClick={() => {
                                          setOpen((pre) => {
                                            return {
                                              ...pre,
                                              [`mymusic${record._id}`]:
                                                !pre[`mymusic${record._id}`],
                                            };
                                          });
                                        }}
                                        OnTouchStart={() => {
                                          setOpen((pre) => {
                                            return {
                                              ...pre,
                                              [`mymusic${record._id}`]:
                                                !pre[`mymusic${record._id}`],
                                            };
                                          });
                                        }}
                                      >
                                        <img
                                          src={Menu}
                                          style={{ height: "25px" }}
                                          alt=""
                                        />
                                      </div>
                                      {open[`mymusic${record._id}`] && (
                                        <div
                                          className="menu-box"

                                          ref={NavigatorRef2}
                                        >
                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() =>
                                              authUtil.isLoggedIn()
                                                ? setPlaylistModal({
                                                  musicId: record._id,
                                                  open: true,
                                                })
                                                : setLoginModal(true)
                                            }
                                            OnTouchStart={() =>
                                              authUtil.isLoggedIn()
                                                ? setPlaylistModal({
                                                  musicId: record._id,
                                                  open: true,
                                                })
                                                : setLoginModal(true)
                                            }
                                          >
                                            Add to playlist
                                          </div>

                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() =>
                                              dispatch(getMusicData(record, index))
                                            }
                                            OnTouchStart={() =>
                                              dispatch(getMusicData(record, index))
                                            }
                                          >
                                            Play song now
                                          </div>
                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() =>
                                              history(`/song/${record._id}`)
                                            }
                                            OnTouchStart={() =>
                                              history(`/song/${record._id}`)
                                            }
                                          >
                                            Details
                                          </div>
                                          <div
                                            className="text-white p-2 cursor-pointer"
                                            onClick={() => {
                                              setDownloadId(record._id);
                                              console.log(
                                                "Download Onclick",
                                                "Download OnTouchStart",
                                                record._id
                                              );
                                              authUtil.isLoggedIn()
                                                ? handledownload(record, index)
                                                : setLoginModal(true);
                                            }}
                                            OnTouchStart={() => {
                                              setDownloadId(record._id);
                                              console.log(
                                                "Download Onclick",
                                                "Download OnTouchStart",
                                                record._id
                                              );
                                              authUtil.isLoggedIn()
                                                ? handledownload(record, index)
                                                : setLoginModal(true);
                                            }}
                                          >
                                            Download
                                          </div>
                                          <div
                                            className="text-white p-2"
                                            onClick={() => {
                                              shareLink(record._id);
                                            }}
                                            OnTouchStart={() => {
                                              shareLink(record._id);
                                            }}
                                          >
                                            <button onClick={copy} OnTouchStart={copy}>
                                              {!copied
                                                ? "Copy link"
                                                : "Copy link"}
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </>
              )}

              {/* new release */}
              <div className="md:flex">
                <div className="md:w-full pt-4 pl-3 pr-3 pl-3-mobile">
                  <div className="flex items-center justify-between overflow-x">
                    <p className="font-size-20 adobe-font white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                      New Released
                    </p>
                    <NavLink to="/newrelease/">
                      <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              {!newRelease?.length && (
                <div className="new-musics flex">No new released music</div>
              )}
              <div className="lg:flex pt-4 scrolling-wrapper">
                {Array.isArray(newRelease) && newRelease?.length > 0 ?
                  newRelease
                  ?.slice(0, 5)
                    // ?.filter(record => record && record.newRelease)
                    ?.map((record, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            className="lg:w-1/5 pl-3 pr-3 card"
                          >
                            {/* <div className="card-holder"> */}

                            <div className="banner-box">
                              <div
                                className="banner-img"

                                onClick={() =>
                                  handlePlaynewRelease(record, index)         // play all free songs on a list one by one from page to page 

                                }
                                OnTouchStart={() =>
                                  handlePlaynewRelease(record, index)}      // play all free songs on a list one by one from page to page 

                              >
                                <img
                                  src={record && record.thumbnail}
                                  className="object-cover"
                                  alt=""
                                />
                              </div>
                              <div className="singer-text p-5">
                                <div className="flex items-center justify-between cursor-pointer">
                                  <div className="line-nowrap">
                                    <p
                                      className="font-size-18 white-text-color font-normal tracking-normal mb-0"
                                      onClick={() =>
                                        handlePlaynewRelease(record, index)} // play all free songs on a list one by one from page to page 

                                      OnTouchStart={() =>
                                        handlePlaynewRelease(record, index)}  // play all free songs on a list one by one from page to page 

                                    >
                                      {record.title}
                                    </p>
                                    <p
                                      className="font-size-18 gray-text-color font-normal tracking-normal mb-0 hover:text-white"
                                      onClick={() => {
                                        history(
                                          `/profile/${record.artistData._id}`
                                        );
                                      }}
                                      OnTouchStart={() => {
                                        history(
                                          `/profile/${record.artistData._id}`
                                        );
                                      }}
                                    >
                                      {record?.artistData?.artistName}
                                    </p>
                                  </div>
                                  {(() => {
                                    if (authUtil.isLoggedIn()) {
                                      return record.isPaid ? (
                                        userUtil
                                          ?.getMusicData()
                                          ?.includes(record._id) ? (
                                          <div className="menu_dd" ref={NavigatorRef2}>
                                            <div
                                              className="menu-img"
                                              onClick={() => {
                                                setOpen((pre) => {
                                                  return {
                                                    ...pre,
                                                    [`newrel${record._id}`]:
                                                      !pre[
                                                      `newrel${record._id}`
                                                      ],
                                                  };
                                                });
                                              }}
                                              OnTouchStart={() => {
                                                setOpen((pre) => {
                                                  return {
                                                    ...pre,
                                                    [`newrel${record._id}`]:
                                                      !pre[
                                                      `newrel${record._id}`
                                                      ],
                                                  };
                                                });
                                              }}
                                            >
                                              <img
                                                src={Menu}
                                                style={{ height: "25px" }}
                                                alt=""
                                              />
                                            </div>{" "}
                                            {open[
                                              `newrel${record._id}`
                                            ] && (
                                                <div
                                                  className="menu-box"
                                                  ref={NavigatorRef2}
                                                >
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      authUtil.isLoggedIn()
                                                        ? setPlaylistModal({
                                                          musicId:
                                                            record._id,
                                                          open: true,
                                                        })
                                                        : setLoginModal(true)
                                                    }
                                                    OnTouchStart={() =>
                                                      authUtil.isLoggedIn()
                                                        ? setPlaylistModal({
                                                          musicId:
                                                            record._id,
                                                          open: true,
                                                        })
                                                        : setLoginModal(true)
                                                    }
                                                  >
                                                    Add to playlist
                                                  </div>

                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      dispatch(getMusicData(record, index)
                                                      )
                                                    }
                                                    OnTouchStart={() =>
                                                      dispatch(getMusicData(record, index)
                                                      )
                                                    }
                                                  >
                                                    Play song now
                                                  </div>
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() =>
                                                      history(
                                                        `/song/${record._id}`
                                                      )
                                                    }
                                                    OnTouchStart={() =>
                                                      history(
                                                        `/song/${record._id}`
                                                      )
                                                    }
                                                  >
                                                    Details
                                                  </div>
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() => {
                                                      setDownloadId(
                                                        record._id
                                                      );
                                                      console.log(
                                                        "Download Onclick",
                                                        "Download OnTouchStart",
                                                        record._id
                                                      );
                                                      authUtil.isLoggedIn()
                                                        ? handledownload(
                                                          record, index
                                                        )
                                                        : setLoginModal(true);
                                                    }}
                                                  >
                                                    Download
                                                  </div>
                                                  <div
                                                    className="text-white p-2 cursor-pointer"
                                                    onClick={() => {
                                                      shareLink(record._id);
                                                    }}
                                                    OnTouchStart={() => {
                                                      shareLink(record._id);
                                                    }}
                                                  >
                                                    <button onClick={copy} OnTouchStart={copy}>
                                                      {!copied
                                                        ? "Copy link"
                                                        : "Copy link"}
                                                    </button>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() =>
                                              handlePlaynewRelease(record, index)   // play all free songs on a list one by one from page to page 

                                            }
                                            OnTouchStart={() =>
                                              handlePlaynewRelease(record, index)}  // play all free songs on a list one by one from page to page 

                                          >
                                            <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                          </div>
                                        )
                                      ) : (
                                        <div
                                          className="menu_dd"
                                          ref={NavigatorRef2}
                                        >
                                          <div
                                            className="menu-img"
                                            onClick={() => {
                                              setOpen((pre) => {
                                                return {
                                                  ...pre,
                                                  [`newrel${record._id}`]:
                                                    !pre[
                                                    `newrel${record._id}`
                                                    ],
                                                };
                                              });
                                            }}
                                            OnTouchStart={() => {
                                              setOpen((pre) => {
                                                return {
                                                  ...pre,
                                                  [`newrel${record._id}`]:
                                                    !pre[
                                                    `newrel${record._id}`
                                                    ],
                                                };
                                              });
                                            }}
                                          >
                                            <img
                                              src={Menu}
                                              style={{ height: "25px" }}
                                              alt=""
                                            />
                                          </div>
                                          {open[`newrel${record._id}`] && (
                                            <div
                                              className="menu-box"
                                              ref={NavigatorRef2}
                                            >
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  authUtil.isLoggedIn()
                                                    ? setPlaylistModal({
                                                      musicId: record._id,
                                                      open: true,
                                                    })
                                                    : setLoginModal(true)
                                                }
                                                OnTouchStart={() =>
                                                  authUtil.isLoggedIn()
                                                    ? setPlaylistModal({
                                                      musicId: record._id,
                                                      open: true,
                                                    })
                                                    : setLoginModal(true)
                                                }
                                              >
                                                Add to playlist
                                              </div>

                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  dispatch(
                                                    getMusicData(record, index)
                                                  )
                                                }
                                                OnTouchStart={() =>
                                                  dispatch(
                                                    getMusicData(record, index)
                                                  )
                                                }
                                              >
                                                Play song now
                                              </div>
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  history(
                                                    `/song/${record._id}`
                                                  )
                                                }
                                                OnTouchStart={() =>
                                                  history(
                                                    `/song/${record._id}`
                                                  )
                                                }
                                              >
                                                Details
                                              </div>
                                              {!record.isPaid && (
                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() => {
                                                    setDownloadId(
                                                      record._id
                                                    );
                                                    console.log(
                                                      "Download Onclick",
                                                      "Download OnTouchStart",
                                                      record._id
                                                    );
                                                    authUtil.isLoggedIn()
                                                      ? handledownload(
                                                        record, index
                                                      )
                                                      : setLoginModal(true);
                                                  }}
                                                >
                                                  Download
                                                </div>
                                              )}
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() => {
                                                  shareLink(record._id);
                                                }}
                                                OnTouchStart={() => {
                                                  shareLink(record._id);
                                                }}
                                              >
                                                <button onClick={copy} OnTouchStart={copy}>
                                                  {!copied
                                                    ? "Copy link"
                                                    : "Copy link"}
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    } else {
                                      return record.isPaid ? (
                                        <div
                                          onClick={() =>
                                            handlePlaynewRelease(record, index)}  // play all free songs on a list one by one from page to page 

                                          OnTouchStart={() =>
                                            handlePlaynewRelease(record, index)}  // play all free songs on a list one by one from page to page 

                                        >
                                          <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                        </div>
                                      ) : (
                                        <div
                                          className="menu_dd"
                                          ref={NavigatorRef2}
                                        >
                                          <div
                                            className="menu-img"
                                            onClick={() => {
                                              setOpen((pre) => {
                                                return {
                                                  ...pre,
                                                  [`newrel${record._id}`]:
                                                    !pre[
                                                    `newrel${record._id}`
                                                    ],
                                                };
                                              });
                                            }}
                                          >
                                            <img
                                              src={Menu}
                                              style={{ height: "25px" }}
                                              alt=""
                                            />
                                          </div>
                                          {open[`newrel${record._id}`] && (
                                            <div
                                              className="menu-box"
                                              ref={NavigatorRef2}
                                            >
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  authUtil.isLoggedIn()
                                                    ? setPlaylistModal({
                                                      musicId: record._id,
                                                      open: true,
                                                    })
                                                    : setLoginModal(true)
                                                }
                                                OnTouchStart={() =>
                                                  authUtil.isLoggedIn()
                                                    ? setPlaylistModal({
                                                      musicId: record._id,
                                                      open: true,
                                                    })
                                                    : setLoginModal(true)
                                                }
                                              >
                                                Add to playlist
                                              </div>
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  dispatch(
                                                    getMusicData(record, index)
                                                  )
                                                }
                                                OnTouchStart={() =>
                                                  dispatch(
                                                    getMusicData(record, index)
                                                  )
                                                }
                                              >
                                                Play song now
                                              </div>
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  history(
                                                    `/song/${record._id}`
                                                  )
                                                }
                                                OnTouchStart={() =>
                                                  history(
                                                    `/song/${record._id}`
                                                  )
                                                }
                                              >
                                                Details
                                              </div>
                                              {!record.isPaid && (
                                                <div
                                                  className="text-white p-2 cursor-pointer"
                                                  onClick={() => {
                                                    setDownloadId(
                                                      record._id
                                                    );
                                                    console.log(
                                                      "Download Onclick",
                                                      "Download OnTouchStart",
                                                      record._id
                                                    );
                                                    authUtil.isLoggedIn()
                                                      ? handledownload(
                                                        record, index
                                                      )
                                                      : setLoginModal(true);
                                                  }}
                                                >
                                                  Download
                                                </div>
                                              )}
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() => {
                                                  shareLink(record._id);
                                                }}
                                                OnTouchStart={() => {
                                                  shareLink(record._id);
                                                }}
                                              >
                                                <button onClick={copy} OnTouchStart={copy}>
                                                  {!copied
                                                    ? "Copy link"
                                                    : "Copy link"}
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }
                                  })()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })
                  : ""}
              </div>
              {/* trending music */}
              <div className="md:flex">
                <div className="md:w-full pt-4 pl-3 pr-3 pt-4-mobile pl-3-mobile">
                  <div className="flex items-center justify-between">
                    <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                      Trending
                    </p>
                    <NavLink to="/trending">
                      <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              {!allTrending?.length && (
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="lg:flex pt-4 scrolling-wrapper">
                {Array.isArray(allTrending) && allTrending?.length > 0 ?
                  allTrending
                    // ?.slice(0)
                    // // ?.reverse()
                    ?.slice(0, 5)
                    // ?.filter(record => record && record.allTrending)
                    ?.map((record, index) => {
                      return (
                        <div
                          key={index}
                          className="lg:w-1/5 pl-3 pr-3 card pl-3-mobile"
                        >
                          <div className="banner-box">
                            <div
                              className="banner-img"
                              onClick={() =>
                                handlePlayallTrending(record, index)   // play all free songs on a list one by one from page to page 

                              }
                              OnTouchStart={() =>
                                handlePlayallTrending(record, index)}  // play all free songs on a list one by one from page to page 

                            >
                              <img
                                src={record && record.thumbnail}
                                loading="lazy"
                                className="object-cover"
                                alt=""
                              />
                            </div>
                            <div className="singer-text p-5">
                              <div className="flex items-center justify-between cursor-pointer">
                                <div className="line-nowrap">
                                  <p
                                    className="font-size-18 white-text-color font-normal tracking-normal mb-0"
                                    onClick={() =>
                                      handlePlayallTrending(record, index)   // play all free songs on a list one by one from page to page
                                    }
                                    OnTouchStart={() =>
                                      handlePlayallTrending(record, index)}   // play all free songs on a list one by one from page to page 

                                  >
                                    {record.title}
                                  </p>
                                  <p
                                    className="font-size-18 gray-text-color font-normal tracking-normal mb-0 hover:text-white"
                                    onClick={() => {
                                      history(
                                        `/profile/${record.artistData._id}`
                                      );
                                    }}
                                    OnTouchStart={() => {
                                      history(
                                        `/profile/${record.artistData._id}`
                                      );
                                    }}
                                  >
                                    {record?.artistData?.artistName}
                                  </p>
                                </div>
                                {(() => {
                                  if (authUtil.isLoggedIn()) {
                                    return record.isPaid ? (
                                      userUtil
                                        ?.getMusicData()
                                        ?.includes(record._id) ? (
                                        <div
                                          className="menu_dd"
                                          ref={NavigatorRef2}
                                        >
                                          <div
                                            className="menu-img"
                                            onClick={() => {
                                              setOpen((pre) => {
                                                return {
                                                  ...pre,
                                                  [`trends${record._id}`]:
                                                    !pre[
                                                    `trends${record._id}`
                                                    ],
                                                };
                                              });
                                            }}
                                          >
                                            <img
                                              src={Menu}
                                              style={{ height: "25px" }}
                                              alt=""
                                            />
                                          </div>
                                          {open[`trends${record._id}`] && (
                                            <div
                                              className="menu-box"
                                              ref={NavigatorRef2}
                                            >
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  authUtil.isLoggedIn()
                                                    ? setPlaylistModal({
                                                      musicId: record._id,
                                                      open: true,
                                                    })
                                                    : setLoginModal(true)
                                                }
                                                OnTouchStart={() =>
                                                  authUtil.isLoggedIn()
                                                    ? setPlaylistModal({
                                                      musicId: record._id,
                                                      open: true,
                                                    })
                                                    : setLoginModal(true)
                                                }
                                              >
                                                Add to playlist
                                              </div>

                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  dispatch(
                                                    getMusicData(record, index)
                                                  )
                                                }
                                                OnTouchStart={() =>
                                                  dispatch(
                                                    getMusicData(record, index)
                                                  )
                                                }
                                              >
                                                Play song now
                                              </div>
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() =>
                                                  history(
                                                    `/song/${record._id}`
                                                  )
                                                }
                                                OnTouchStart={() =>
                                                  history(
                                                    `/song/${record._id}`
                                                  )
                                                }
                                              >
                                                Details
                                              </div>
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() => {
                                                  setDownloadId(record._id);
                                                  console.log(
                                                    "Download Onclick",
                                                    "Download OnTouchStart",
                                                    record._id
                                                  );
                                                  authUtil.isLoggedIn()
                                                    ? handledownload(record, index)
                                                    : setLoginModal(true);
                                                }}
                                              >
                                                Download
                                              </div>
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() => {
                                                  shareLink(record._id);
                                                }}
                                                OnTouchStart={() => {
                                                  shareLink(record._id);
                                                }}
                                              >
                                                <button onClick={copy} OnTouchStart={copy}>
                                                  {!copied
                                                    ? "Copy link"
                                                    : "Copy link"}
                                                </button>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          onClick={() =>
                                            authUtil.isLoggedIn()
                                              ? setShowModal({
                                                musicId: record._id,
                                                open: true,
                                                amount: record.amount,
                                                musicTitle: record.title,
                                                artistId:
                                                  record.artistData._id,
                                              })
                                              : setLoginModal(true)
                                          }
                                        >
                                          <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                        </div>
                                      )
                                    ) : (
                                      <div
                                        className="menu_dd"
                                        ref={NavigatorRef2}
                                      >
                                        <div
                                          className="menu-img"
                                          onClick={() => {
                                            setOpen((pre) => {
                                              return {
                                                ...pre,
                                                [`trends${record._id}`]:
                                                  !pre[`trends${record._id}`],
                                              };
                                            });
                                          }}
                                          OnTouchStart={() => {
                                            setOpen((pre) => {
                                              return {
                                                ...pre,
                                                [`trends${record._id}`]:
                                                  !pre[`trends${record._id}`],
                                              };
                                            });
                                          }}
                                        >
                                          <img
                                            src={Menu}
                                            style={{ height: "25px" }}
                                            alt=""
                                          />
                                        </div>

                                        {open[`trends${record._id}`] && (
                                          <div
                                            className="menu-box"
                                            ref={NavigatorRef2}
                                          >
                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() =>
                                                authUtil.isLoggedIn()
                                                  ? setPlaylistModal({
                                                    musicId: record._id,
                                                    open: true,
                                                  })
                                                  : setLoginModal(true)
                                              }
                                              OnTouchStart={() =>
                                                authUtil.isLoggedIn()
                                                  ? setPlaylistModal({
                                                    musicId: record._id,
                                                    open: true,
                                                  })
                                                  : setLoginModal(true)
                                              }
                                            >
                                              Add to playlist
                                            </div>

                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() =>
                                                dispatch(getMusicData(record, index))
                                              }
                                              OnTouchStart={() =>
                                                dispatch(getMusicData(record, index))
                                              }
                                            >
                                              Play song now
                                            </div>
                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() =>
                                                history(`/song/${record._id}`)
                                              }
                                              OnTouchStart={() =>
                                                history(`/song/${record._id}`)
                                              }
                                            >
                                              Details
                                            </div>
                                            {!record.isPaid && (
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() => {
                                                  setDownloadId(record._id);
                                                  console.log(
                                                    "Download Onclick",
                                                    "Download OnTouchStart",
                                                    record._id
                                                  );
                                                  authUtil.isLoggedIn()
                                                    ? handledownload(record, index)
                                                    : setLoginModal(true);
                                                }}
                                              >
                                                Download
                                              </div>
                                            )}
                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() => {
                                                shareLink(record._id);
                                              }}
                                            >
                                              <button onClick={copy} OnTouchStart={copy}>
                                                {!copied
                                                  ? "Copy link"
                                                  : "Copy link"}
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  } else {
                                    return record.isPaid ? (
                                      <div
                                        onClick={() =>
                                          handlePlayallTrending(record, index)   // play all free songs on a list one by one from page to page 

                                        }
                                        OnTouchStart={() =>
                                          handlePlayallTrending(record, index)}  // play all free songs on a list one by one from page to page 

                                      >
                                        <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                      </div>
                                    ) : (
                                      <div
                                        className="menu_dd"
                                        ref={NavigatorRef2}
                                      >
                                        <div
                                          className="menu-img"
                                          onClick={() => {
                                            setOpen((pre) => {
                                              return {
                                                ...pre,
                                                [`trends${record._id}`]:
                                                  !pre[`trends${record._id}`],
                                              };
                                            });
                                          }}

                                        >
                                          <img
                                            src={Menu}
                                            style={{ height: "25px" }}
                                            alt=""
                                          />
                                        </div>
                                        {open[`trends${record._id}`] && (
                                          <div
                                            className="menu-box"
                                            ref={NavigatorRef2}
                                          >
                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() =>
                                                authUtil.isLoggedIn()
                                                  ? setPlaylistModal({
                                                    musicId: record._id,
                                                    open: true,
                                                  })
                                                  : setLoginModal(true)
                                              }
                                              OnTouchStart={() =>
                                                authUtil.isLoggedIn()
                                                  ? setPlaylistModal({
                                                    musicId: record._id,
                                                    open: true,
                                                  })
                                                  : setLoginModal(true)
                                              }
                                            >
                                              Add to playlist
                                            </div>

                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() =>
                                                dispatch(getMusicData(record, index))
                                              }
                                              OnTouchStart={() =>
                                                dispatch(getMusicData(record, index))
                                              }
                                            >
                                              Play song now
                                            </div>
                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() =>
                                                history(`/song/${record._id}`)
                                              }
                                              OnTouchStart={() =>
                                                history(`/song/${record._id}`)
                                              }
                                            >
                                              Details
                                            </div>
                                            {!record.isPaid && (
                                              <div
                                                className="text-white p-2 cursor-pointer"
                                                onClick={() => {
                                                  setDownloadId(record._id);
                                                  console.log(
                                                    "Download Onclick",
                                                    "Download OnTouchStart",
                                                    record._id
                                                  );
                                                  authUtil.isLoggedIn()
                                                    ? handledownload(record, index)
                                                    : setLoginModal(true);
                                                }}
                                                OnTouchStart={() => {
                                                  setDownloadId(record._id);
                                                  console.log(
                                                    "Download Onclick",
                                                    "Download OnTouchStart",
                                                    record._id
                                                  );
                                                  authUtil.isLoggedIn()
                                                    ? handledownload(record, index)
                                                    : setLoginModal(true);
                                                }}
                                              >
                                                Download
                                              </div>
                                            )}
                                            <div
                                              className="text-white p-2 cursor-pointer"
                                              onClick={() => {
                                                shareLink(record._id);
                                              }}
                                              OnTouchStart={() => {
                                                shareLink(record._id);
                                              }}
                                            >
                                              <button onClick={copy} OnTouchStart={copy}>
                                                {!copied
                                                  ? "Copy link"
                                                  : "Copy link"}
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                })()}
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                      );
                    })
                  : ""}
              </div>
              {/* free music */}
              <div className="md:flex">
                <div className="md:w-full pt-4 pl-3 pr-3 pl-3-mobile">
                  <div className="flex items-center justify-between">
                    <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                      Free Downloads
                    </p>
                    <NavLink to="/freedownloads">
                      <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              {!allFree?.length && (
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="lg:flex pt-4 scrolling-wrapper">
                {Array.isArray(allFree) && allFree?.length > 0 ?
                  allFree
                    // ?.slice(0)
                    // // ?.reverse()
                    ?.slice(0, 5)
                    // ?.filter(record => record && record.allFree)
                    ?.map((record, index) => {
                      return (
                        <div
                          key={index}
                          className="lg:w-1/5 pl-3 pr-3 card pl-3-mobile"
                        >
                          <div className="banner-box">
                            <div
                              className="banner-img"
                              onClick={() =>
                                handlePlayallFree(record, index)   // play all free songs on a list one by one from page to page 
                              }
                              OnTouchStart={() =>
                                handlePlayallFree(record, index)}  // play all free songs on a list one by one from page to page
                            >
                              <img
                                src={record && record.thumbnail}
                                loading="lazy"
                                className="object-cover"
                                alt=""
                              />
                            </div>
                            <div className="singer-text p-5">
                              <div className="flex items-center justify-between cursor-pointer">
                                <div className="line-nowrap">
                                  <p
                                    className="font-size-18 white-text-color font-normal tracking-normal mb-0"
                                    onClick={() =>
                                      handlePlayallFree(record, index)   // play all free songs on a list one by one from page to page 
                                    }
                                    OnTouchStart={() =>
                                      handlePlayallFree(record, index)}  // play all free songs on a list one by one from page to page
                                  >
                                    {record.title}
                                  </p>
                                  <p
                                    className="font-size-18 gray-text-color font-normal tracking-normal mb-0 hover:text-white"
                                    onClick={() => {
                                      history(
                                        `/profile/${record?.artistData?._id}`
                                      );
                                    }}
                                    OnTouchStart={() => {
                                      history(
                                        `/profile/${record?.artistData?._id}`
                                      );
                                    }}
                                  >
                                    {record?.artistData?.artistName}
                                  </p>
                                </div>
                                <div>
                                  <div
                                    className="menu_dd"
                                    ref={NavigatorRef2}
                                  >
                                    <div
                                      className="menu-img"
                                      onClick={() => {
                                        setOpen((pre) => {
                                          return {
                                            ...pre,
                                            [`freedownload${record._id}`]:
                                              !pre[
                                              `freedownload${record._id}`
                                              ],
                                          };
                                        });
                                      }}

                                    >
                                      <img
                                        src={Menu}
                                        style={{ height: "25px" }}
                                        alt=""
                                      />
                                    </div>

                                    {open[`freedownload${record._id}`] && (
                                      <div
                                        className="menu-box"
                                        ref={NavigatorRef2}
                                      >
                                        <div
                                          className="text-white p-2 cursor-pointer"
                                          onClick={() =>
                                            authUtil.isLoggedIn()
                                              ? setPlaylistModal({
                                                musicId: record._id,
                                                open: true,
                                              })
                                              : setLoginModal(true)
                                          }
                                          OnTouchStart={() =>
                                            authUtil.isLoggedIn()
                                              ? setPlaylistModal({
                                                musicId: record._id,
                                                open: true,
                                              })
                                              : setLoginModal(true)
                                          }
                                        >
                                          Add to playlist
                                        </div>
                                        <div
                                          className="text-white p-2 cursor-pointer"
                                          onClick={() =>
                                            dispatch(getMusicData(record, index))
                                          }
                                          OnTouchStart={() =>
                                            dispatch(getMusicData(record, index))
                                          }
                                        >
                                          Play song now
                                        </div>
                                        <div
                                          className="text-white p-2 cursor-pointer"
                                          onClick={() =>
                                            history(`/song/${record._id}`)
                                          }
                                          OnTouchStart={() =>
                                            history(`/song/${record._id}`)
                                          }
                                        >
                                          Details
                                        </div>
                                        <div
                                          className="text-white p-2 cursor-pointer"
                                          onClick={() => {
                                            setDownloadId(record._id);
                                            console.log(
                                              "Download Onclick",
                                              "Download OnTouchStart",
                                              record._id
                                            );
                                            authUtil.isLoggedIn()
                                              ? handledownload(record, index)
                                              : setLoginModal(true);
                                          }}
                                        >
                                          Download
                                        </div>
                                        <div
                                          className="text-white p-2 cursor-pointer"
                                          onClick={() => {
                                            shareLink(record._id);
                                          }}
                                          OnTouchStart={() => {
                                            shareLink(record._id);
                                          }}
                                        >
                                          <button onClick={copy} OnTouchStart={[copy]}>
                                            {!copied
                                              ? "Copy link"
                                              : "Copy link"}
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>

              {/* all albums */}
              <div className="md:flex">
                <div className="md:w-full pt-4 pl-3 pr-3 pl-3-mobile">
                  <div className="flex items-center justify-between">
                    <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                      Fresh Albums
                    </p>
                    <NavLink to="/freshalbums">
                      <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              {!allAlbum?.length && (
                <div className="new-musics flex">No Albums Uploaded</div>
              )}
              <div className="lg:flex pt-4 scrolling-wrapper">
                {Array.isArray(allAlbum) && allAlbum?.length > 0 ?
                  allAlbum
                    ?.slice(0, 5)
                    // ?.filter(record => record && record.albumTitle)
                    ?.map((record, index) => {
                      return (

                        <div
                          key={index}
                          className="lg:w-1/5 pl-3 pr-3 card pl-3-mobile"
                          onClick={() => history(`/albumplay/${record._id}`)}
                          OnTouchStart={() => history(`/albumplay/${record._id}`)}
                        >
                          <div className="banner-box">
                            <div
                              className="banner-img"
                              onClick={() =>
                                handlePlayallAlbum(record, index)}  // play all free songs on a list one by one from page to page 

                            >
                              <img
                                src={record && record.image}
                                loading="lazy"
                                className="object-cover"
                                alt=""
                              />
                            </div>
                            <div className="singer-text p-5">
                              <div className="flex items-center justify-between cursor-pointer">
                                <div className=" ">
                                  <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                    {record.name}
                                  </p>
                                  <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                    {record.desc}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>

              {/* artists */}
              <div className="md:flex">
                <div className="md:w-full pt-4 pl-3 pr-3 pl-3-mobile">
                  <div className="flex items-center justify-between">
                    <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                      Artist you may like
                    </p>
                    <NavLink to="/artist">
                      <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              {!allArtists?.length && (
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="cus-grid-one pt-5">
                {Array.isArray(allArtists) && allArtists?.length > 0 ?
                  allArtists
                    ?.slice(0, 6)
                    // ?.filter(record => record && record.artistName)
                    ?.map((rec, index) => {
                      return (
                        <div key={index} className="grid-items cursor-pointer">
                          <div
                            className="artist-profile-main"
                            onClick={() => {
                              history(`/profile/${rec._id}`);
                            }}
                          >
                            <div className="artist-profile1 flex justify-center">
                              <img
                                className="rounded-full"
                                loading="lazy"
                                src={
                                  rec.profile
                                    ? rec.profile
                                    : avatar
                                }
                                alt=""
                              />
                            </div>
                            <div>
                              <p className="font-size-20 white-text-color font-medium tracking-normal text-center pt-4 mb-0 cursor-pointer">
                                {rec.artistName}
                                {/* {rec.realName} */}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
              {/* music by mood */}
              <div className="md:flex">
                <div className="md:w-full pt-4 pl-3 pr-3 pl-3-mobile">
                  <div className="flex items-center justify-between">
                    <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                      Music By Genre
                    </p>
                    <NavLink to="/musicbygenre">
                      <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              {!allGenre?.length && (
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="lg:flex pt-4 scrolling-wrapper">
                {Array.isArray(allGenre) && allGenre?.length > 0 ?
                  allGenre
                    ?.slice(0, 5)
                    // ?.filter(record => record && record.genreTitle)
                    ?.map((record, index) => {
                      return (
                        <div
                          key={index}
                          className="lg:w-1/5 pl-3 pr-3 card pl-3-mobile"
                          onClick={() => {
                            history(`/music/${record.genre._id}`);
                          }}

                        >
                          <div className="banner-box">
                            <div className="banner-img">
                              <img
                                className="object-cover"
                                src={record?.genre?.genreImage}
                                alt={record?.genre?.genreTitle}
                              />
                            </div>
                            <div className="singer-text p-5">
                              <div className="flex items-center justify-between cursor-pointer">
                                <div className="line-nowrap">
                                  <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                    {record?.genre?.genreTitle}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
              {/* music by country */}
              <div className="md:flex">
                <div className="md:w-full pt-4 pl-3 pr-3 pl-3-mobile">
                  <div className="flex items-center justify-between">
                    <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                      Music By Country
                    </p>
                    <NavLink to="/allcountrymusic">
                      <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              {!countryList?.length && (
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="lg:flex pt-4 scrolling-wrapper">
                {Array.isArray(countryList) && countryList?.length > 0 ?
                  countryList
                    ?.slice(0, 5)
                    // ?.filter(record => record && record.countryList)
                    ?.map((record, index) => {
                      return (
                        <div
                          key={index}
                          className="lg:w-1/5 pl-3 pr-3 card pl-3-mobile"
                          onClick={() => {
                            history(`/musicbycountry/${record}`, { state: { recordName: record } });
                          }}

                        >
                          <div className="banner-box">
                            <div className="banner-img">
                              <img
                                className="object-cover"
                                src={require(`../../Assets/Countries/${countries
                                  ?.find((c) => c.Name === record)
                                  ?.Code?.toLowerCase()}.png`)}
                                alt={record}
                              />
                            </div>
                            <div className="singer-text p-5">
                              <div className="flex items-center justify-between cursor-pointer">
                                <div className="line-wrap overflow-hidden">
                                  <p className="font-size-18 white-text-color font-normal tracking-normal mb-0 overflow-hidden overflow-ellipsis">
                                    {record}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
              {/* playlists */}
              <div className="md:flex">
                <div className="md:w-full pt-4 pl-3 pr-3 pl-3-mobile">
                  <div className="flex items-center justify-between">
                    <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                      Playlists for you
                    </p>
                    <NavLink to="/global-playlist">
                      <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              {!allPlaylist?.length && (
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="lg:flex pt-4 scrolling-wrapper">
                {Array.isArray(allPlaylist) && allPlaylist?.length > 0 ?
                  allPlaylist
                    ?.slice(0, 5)
                    // ?.filter(record => record && record.allPlaylist)
                    ?.map((record, index) => {
                      if (!record.isPrivate) {
                        return (
                          <div
                            key={index}
                            className="lg:w-1/5 pl-3 pr-3 card pl-3-mobile"
                          >
                            <div className="banner-box">
                              <div
                                className="banner-img"
                                onClick={() => history(`/play/${record._id}`)}
                              >
                                <img
                                  src={
                                    record?.Songs[record?.Songs?.length - 1]
                                      ?.musicImage ||
                                    "https://cdn.dribbble.com/users/60166/screenshots/5394073/music_logo.jpg"
                                  }
                                  loading="lazy"
                                  className="object-cover"
                                  alt=""
                                />
                              </div>
                              <div className="singer-text p-5">
                                <div
                                  className="flex items-center justify-between cursor-pointer"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    WebkitLineClamp: 3,
                                  }}
                                >
                                  <div
                                    className="line-nowrapYour Playlists"
                                    onClick={() =>
                                      history(`/play/${record._id}`)
                                    }

                                  >
                                    <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                      {record.playlist_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  : ""}
              </div>
              {/* your playlists */}
              <div className="md:flex ">
                <div className="md:w-full pt-4 pl-3 pr-3 pl-3-mobile">
                  <div className="flex items-center justify-between">
                    <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                      Your Playlists
                    </p>
                    <NavLink to="/playlist">
                      <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              {!allMyPlaylist?.length && (
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="lg:flex pt-4 scrolling-wrapper">
                {Array.isArray(allMyPlaylist) && allMyPlaylist?.length > 0 ?
                  allMyPlaylist
                    ?.slice(0, 5)
                    // ?.filter(record => record && record.allPlaylist)
                    ?.map((record, index) => {
                      if (!record.isPrivate) {
                        return (
                          <div
                            key={index}
                            className="lg:w-1/5 pl-3 pr-3 card pl-3-mobile"
                          >
                            <div className="banner-box">
                              <div
                                className="banner-img"
                                onClick={() => history(`/play/${record._id}`)}
                              >
                                <img
                                  src={
                                    record?.Songs[record?.Songs?.length - 1]
                                      ?.musicImage ||
                                    "https://cdn.dribbble.com/users/60166/screenshots/5394073/music_logo.jpg"
                                  }
                                  loading="lazy"
                                  className="object-cover"
                                  alt=""
                                />
                              </div>
                              <div className="singer-text p-5">
                                <div
                                  className="flex items-center justify-between cursor-pointer"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    WebkitLineClamp: 3,
                                  }}
                                >
                                  <div
                                    className="line-nowrap"
                                    onClick={() =>
                                      history(`/play/${record._id}`)
                                    }

                                  >
                                    <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                      {record.playlist_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  : ""}
              </div>

              {/* All Videos */}
              <div className="md:flex ">
                <div className="md:w-full pt-4 pl-3 pr-3 pl-3-mobile">
                  <div className="flex items-center justify-between">
                    <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                      Videos
                    </p>
                    <NavLink to="/video">
                      <p className="font-size-20 white-text-color font-bold adobe-font tracking-normal cursor-pointer">
                        See All
                      </p>
                    </NavLink>
                  </div>
                </div>
              </div>
              {!allVideos?.length && (
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              )}
              <div className="lg:flex pt-4 scrolling-wrapper ">
                {Array.isArray(allVideos) && allVideos?.length > 0 ?

                  allVideos
                    ?.slice(0, 5)
                    // ?.filter(record => record && record.allVideos)
                    ?.map((record, index) => {
                      if (!record.isPrivate) {
                        return (
                          <div
                            key={index}
                            className="lg:w-1/5 pl-3 pr-3 card pl-3-mobile"
                          >
                            <div className="banner-box">
                              <div
                                className="banner-img"
                                onClick={() => {
                                  history({
                                    pathname: `/video`,
                                    state: { recordName: record },
                                  });
                                }}

                              >
                                <video
                                  src={`${record?.video}#t=00:00:10`}
                                  loading="lazy"
                                  controls={false}
                                  muted={true}
                                  autoPlay={false}
                                  className="object-cover pointer-events-none"
                                />
                              </div>
                              <div className="singer-text p-5">
                                <div
                                  className="flex items-center justify-between cursor-pointer"
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    WebkitLineClamp: 3,
                                  }}
                                >
                                  <div
                                    className="line-nowrap"
                                  >
                                    <p className="font-size-18 white-text-color font-normal tracking-normal mb-0">
                                      {record.title}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                  : ""}
              </div>

              {/* //* Playlist modal */}
              {playlistModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      <div className="playlist border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="justify-content-center p-5 relative  border-solid border-white-200 rounded-t">
                          <h3 className="text-2xl text-center font-bold text-white">
                            Select Playlist
                          </h3>

                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }

                          >
                            <p className="button-center-modal">
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                          <p className="my-4 text-white text-lg leading-relaxed">
                            <div
                              className="flex items-center flex-row flex-wrap pt-4"
                              style={{
                                height: "450px",
                                overflowY: "overlay",
                              }}
                            >
                              {Array.isArray(userPlaylist) && userPlaylist?.length > 0 ?
                                userPlaylist
                                ?.slice(0, 5)
                                // ?.filter(record => record && record.userPlaylist)
                                  ?.map((record, index) => {
                                    return (
                                      <div
                                        className="pl-3 pr-3 cursor-pointer "
                                        key={index}
                                        onClick={() => {
                                          handleAddToPlaylist(record._id);
                                        }}

                                      >
                                        <div className="card-holder">
                                          <div className="singer-box">
                                            <div className="singer-img">
                                              <img
                                                src={
                                                  (record &&
                                                    record.Songs &&
                                                    record.Songs?.length &&
                                                    record.Songs[0].musicImage) ||
                                                  `https://source.unsplash.com/1600x900/?${record.playlist_name}`

                                                }
                                                alt=""
                                                style={{
                                                  height: "150px",
                                                  width: "335px",
                                                }}
                                              />
                                            </div>
                                            <div className="singer-text p-4">
                                              <div className="flex items-center justify-between">
                                                <div>
                                                  <p className="font-size-14 white-text-color font-normal tracking-normal mb-0">
                                                    {record.playlist_name}
                                                  </p>
                                                </div>
                                                <div>
                                                  {record
                                                    ?.Songs
                                                    ?.map((rec) => {
                                                      if (
                                                        rec._id ===
                                                        playlistModal.musicId
                                                      ) {
                                                        return (
                                                          <>
                                                            <p className="cursor-pointer font-size-14 login-text-color">
                                                              added
                                                              <i className="pl-1 fas fa-check cursor-pointer font-size-14 login-text-color"></i>
                                                            </p>
                                                          </>
                                                        );
                                                      }
                                                      return null;
                                                    })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : (
                                  <div className="justify-content-center widthFull">
                                    <h1 className="text-white text-2xl mt-10">
                                      Playlist Is Empty.
                                    </h1>
                                  </div>
                                )}
                            </div>
                          </p>
                        </div>
                        <div className="flex flex space-x-24 items-center justify-end p-4  border-solid border-white">
                          <button className="w-full text-left">
                            <NavLink
                              className={`s-sidebar__nav-link ${route === "/create-playlist" ? "s-sidebar__nav-link-active" : ""
                                }`}
                              to="/create-playlist"
                            >
                              <div className="sidebar-img flex justify-center items-center">
                                {route === "/create-playlist" ?
                                  (
                                    <img
                                      src=""
                                      alt=""
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src=""
                                        alt=""
                                      />
                                      <span style={{ marginLeft: 100, textWrap: 'nowrap' }} className="closebtn text-white border-solid border border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                                      >
                                        Create Playlist
                                      </span>
                                    </>
                                  )}
                              </div>
                            </NavLink>
                          </button>
                          <button
                            className="closebtn text-white border-solid border border-white font-bold uppercase px-6 py-2 text-sm  mr-1 mb-1 ease-linear transition-all duration-150"
                            type="button"
                            onClick={() =>
                              setPlaylistModal({
                                ...playlistModal,
                                open: false,
                              })
                            }

                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {/* //* Purchase modal */}
              {showModal.open ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      <div className="poppop border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between pt-5 pr-5 pl-5 border-solid border-white-100 rounded-t-lg">
                          <h3 className="text-2xl font-bold text-white">
                            Buy this song or album
                          </h3>
                          <button
                            className="p-1 ml-auto bg-transparent border-0 text-white  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() =>
                              setShowModal({
                                ...showModal,
                                open: false,
                              })
                            }

                          >
                            <p>
                              <i className="fas fa-times"></i>
                            </p>
                          </button>
                        </div>
                        {/*body*/}
                        <div className="relative pl-6 pr-6 pb-6 flex-auto rounded-b-lg">
                          <p className="mb-3 text-white leading-relaxed">
                            Like this artists music? Show your support by
                            purchasing their music.
                          </p>

                          <p className="mb-3 text-white text-lg leading-relaxed">
                            Title: {showModal.musicTitle}
                          </p>
                          <p className="mb-3 text-white text-lg leading-relaxed">
                            Price: ${showModal.amount}
                          </p>
                          <Elements stripe={stripePromise}>
                            <Payments
                              musicId={showModal.musicId}
                              amount={showModal.amount}
                              musicTitle={showModal.musicTitle}
                              musicArtistId={showModal.artistId}
                            />
                          </Elements>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}

              {forgotModal ? (
                <Forgotpassword
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {loginModal ? (
                <Userlogin
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}

              {signupModal ? (
                <UserSignup
                  setSignupModal={setSignupModal}
                  setLoginModal={setLoginModal}
                  setForgotModal={setForgotModal}
                />
              ) : null}
            </div>
          </div>
          {loading && <LoadingBalls />}
          <CookieConsent
            flipButtons
            location="center"
            buttonText="Accept All Cookies"
            cookieName="myAwesomeCookieName2"
            style={{
              background: "#142524",
              padding: "20px",
              borderRadius: "10px", // Adds smooth rounded corners
              color: "#fff",
              textAlign: "left",
              maxWidth: "400px", // Keeps it compact
            }}
            buttonStyle={{
              borderRadius: "4px",
              background: "#1DB954",
              color: "#fff",
              fontSize: "16px",
              padding: "10px 20px",
              border: "none",
              cursor: "pointer",
            }}
            expires={150}
          >
            <h3 className="font-bold text-lg">Cookies Notice</h3>
            <p className="text-sm mt-2">
              We use cookies to enhance your browsing experience, serve personalized ads or content,
              and analyze our traffic. By clicking <strong>"Accept All Cookies,"</strong> you agree
              to our use of cookies. You can manage your cookie preferences through the
              <strong> "Settings"</strong> option.
            </p>

            <NavLink
              to="/privacypolicy"
              className="pl-3 font-semibold text-green-400 hover:text-green-300"
            >
              Privacy Policy
            </NavLink>
          </CookieConsent>

        </main>
      </div>
    </div>
  );
};

export default Dashboard;
