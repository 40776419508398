/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { ApiGet, ApiPost } from "../../../helpers/API/ApiData";
import { isLoggedIn } from "../../../utils/auth.util";
import * as userUtil from "../../../utils/user.util";
import "../../js/Playlist/playlist.scss";
import Header from "../../Layout/Header";
import Sidebar from "../../Layout/Sidebar";
import * as userUtils from "../../../utils/user.util";

import { useDispatch, useSelector } from "react-redux";
import { getMusicData } from "../../../store/reducers/MusicData/action";
import Menu from "../../../Assets/Images/dots.png";
import LoadingBalls from "../../../Assets/LoadingBalls";
import { ToastContainer, toast } from "react-toastify";
import useOnClickOutside from "../../../helpers/outsideClick";
import * as authUtil from "../../../utils/auth.util";
import axios from "axios";
import { Tooltip } from "@material-ui/core";


export default function AlbumPlay() {
  const route = window.location.pathname;
  var playlistId = route.split("/").slice(-1)[0];
  const [playlistData, setPlaylistdata] = useState();
  const [palylistLastimage, setPlaylistLastImage] = useState();
  const [albumData, setAlbumdata] = useState();
  const [albumLastImage, setAlbumLastImage] = useState();
  const [highlight, setHighlight] = useState();
  const [dataChange, setDataChange] = useState();
  const [loading, setLoading] = useState(false);
  const user = userUtil.getUserInfo();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);


  const allVideos = useSelector((state) => state.MusicData);
  console.log("allvideos", allVideos);
  console.log("playlistId", playlistId);
  useEffect(() => {
    getPlaylistData();
    // getAlbumData();
  }, [dataChange]);
  const [dId, setDownloadId] = useState();
  const shareLink = (id) => {
    navigator.clipboard.writeText(`${window.location.href}song/${id}`);
    toast.success("Link Copied..");
  };

  const [copied, setCopied] = useState(false);
  function copy() {
    const el = document.createElement("input");
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  const getAlbumData = async (albumId) => {
    setLoading(true);
    await ApiGet(`album/${albumId}`)
      .then((res) => {
        setLoading(false);
        console.log("aollboom Data", res.data.data);
        setAlbumdata(res.data.data.album);
        setAlbumLastImage(res.data.data.album.image);
      })
      .catch((err) => {
        console.log("errro", err);
      });
  };

  const getPlaylistData = async () => {
    setLoading(true);
    await ApiGet(`album/get-music-by-album/${playlistId}`)
      .then((res) => {
        setLoading(false);
        console.log("palbum Data", res.data.data);
        setPlaylistdata(res.data.data.music);
        setAlbumdata(res.data.data.album);
        setPlaylistLastImage(res.data.data.album.image);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  // console.log({palylistLastimage})
  const handleRemoveFromPlaylist = async (id) => {
    const data = {
      musicId: id,
      playlistId: playlistId,
    };
    await ApiPost("playlist/remove-from-playlist", data)
      .then((res) => {
        console.log("res", res);
        setDataChange("music removed");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const [show, setShow] = useState(false);  // this is the definition of onmouseEnter and onMouseLeave
  const [playlistModal, setPlaylistModal] = useState({
    open: false,
    musicId: null,
  });


  const handleMusicClick = (index, record) => {
    setHighlight(index);
    if (isLoggedIn()) {
      !(!record.isPaid && userUtil?.getMusicData()?.includes(record._id))
        ? dispatch(
          getMusicData({
            playlistData: { ...playlistData },
            index: index,
          })
        )
        : toast.error("Purchase the song first!!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          closeOnTouchStart: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    } else {
      console.log("albumData", playlistData);
      let songArray = playlistData?.filter((item) => {
        console.log("isPaid", item.isPaid);
        return item.isPaid === false;
      });

      dispatch(
        getMusicData({
          playlistData: { ...playlistData, Songs: songArray },
          index: index,
        })
      );
    }
  };
  const handledownload = async (data) => {
    let url = data?.musicUrl;
    let title = data?.musicTitle ? data?.musicTitle : data?.title;
    toast.success("Your download will begin shortly");
    setLoading(true);
    await axios({
      url: url,
      method: "GET",
      responseType: "blob",
      headers: {
        "Content-Type": "audio/mpeg",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(async (response) => {
        // handleSendEmail(data._id);
        setLoading(false);
        console.log("Response", response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${title}.mp3`);  //or any other extension
        document.body.appendChild(link);
        link.click();
        downloadUserList(data);
        await downloadMusic(data._id);
      })
      .catch((err) => {
        setLoading(false);
        console.log("errDownload", err);
      });
  };
  const downloadMusic = async (id) => {
    console.log("Downloading");
    await ApiGet(`music/download?musicId=${id}`)
      .then((res) => {
        console.log("Downloads", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const [selectedSongIndex, setSelectedSongIndex] = useState();


  const downloadUserList = async (data) => {
    console.log("-");
    const body = {
      musicId: data._id,
      userId: userId,
      artistId: data.artistData._id,
    };

    await ApiPost(
      `musicDownloadListForArtist/create-musicDownloadListForArtist`,
      body
    )
      .then((res) => {
        console.log("-");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const [open, setOpen] = useState({});
  const NavigatorRef2 = useRef();
  const userId = userUtils.getUserInfo()?.user?.id;


  useOnClickOutside(NavigatorRef2, () => {
    setOpen({});
  });

  // play all free songs on a list one by one from page to page 
  const handlePlayplaylistData = (record, key) => {
    if (record.isPaid) {
      if (authUtil.isLoggedIn()) {
        setShowModal({
          musicId: record._id,
          open: true,
          amount: record.amount,
          musicTitle: record.title,
          artistId: record.artistData?._id,
        });
      } else {
        setLoginModal(true);
      }
    } else {
      dispatch(getMusicData({ playlistData: playlistData, index: key }));
    }
  };

  return (
    <div>
      <>
        <Header />
      </>
      <div className="flex">
        <>
          <Sidebar />
        </>
        <main className="s-layout__content">
          <div className="main-box">
            <div className="container-fluid">
              <div className="md:flex pt-24">
                <div className="md:w-full pt-5 flex items-center ">
                  <NavLink to="/" onClick={(e) => {
                    e.preventDefault();
                    history(-1)
                  }}
                    OnTouchStart={(e) => {
                      e.preventDefault();
                      history(-1)
                    }}
                  >
                    <p>
                      <i className="fas fa-arrow-left cursor-pointer icon-font-size text-white"></i>
                    </p>
                  </NavLink>
                  <p className="pl-3 font-size-35 white-text-color font-medium tracking-normal text-center mb-0">
                    Albums
                  </p>
                </div>
              </div>
              <div className="lg:flex pt-5">
                <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 w-full pl-3 pr-3">
                  <div
                    className="play-main-banner flex items-between"
                    style={{
                      backgroundImage: `url(${palylistLastimage ||
                        `https://source.unsplash.com/1600x900/?${playlistData?.playlist_name}`
                        })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      borderRadius: "25px",
                    }}
                  >

                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 p-1 m-1 ">
                      <img
                        src={
                          (palylistLastimage && palylistLastimage) ||
                          "https://cdn.dribbble.com/users/60166/screenshots/5394073/music_logo.jpg"
                        }
                        alt=""
                        className="play-child-image-border-radius rounded-2xl p-4 m-4 "
                        style={{
                          width: "250px",
                          height: "250px",
                          borderRadius: "35px",
                        }}
                      />
                      <h3 className="text-white font-size-35 mb-auto">
                        Album : {albumData?.name}
                      </h3>
                      <h3 className="text-white font-size-39">
                        {albumData?.desc}
                      </h3>
                      <h3 className="text-white">
                        Total Music: {playlistData?.length}
                      </h3>
                    </div>
                  </div>
                  <div className="pt-4"></div>
                  <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-1">
                    <div className="play-table1">
                      <table width="100%">
                        <thead className="text-white">
                          <tr>
                            <td className="pl-3 px-2" >Title</td>
                            <td className="pl-3 px-2">Artist</td>
                          </tr>
                        </thead>
                        <tbody className="play-table-details1">
                          {playlistData?.length ? (
                            playlistData?.map((record, index) => { // this is where music are fetched in all pages
                              return (
                                <tr
                                  key={index}
                                  className={`${highlight
                                      ? highlight === index && "table-heighlite"
                                      : index === 0 && "table-heighlite"
                                    }`}
                                >

                                  <td
                                    className="text-left cursor-pointer"

                                  >
                                    <span
                                      className={`font-size-16 tracking-normal font-medium cursor-pointer  ${highlight
                                          ? highlight === index &&
                                          "white-text-color"
                                          : index === 0 && "white-text-color"
                                        }`}
                                      // play all album songs on a list one by one from page to page
                                      onClick={() =>
                                        handlePlayplaylistData(record, index)
                                      }

                                      OnTouchStart={() =>
                                        handlePlayplaylistData(record, index)
                                      }

                                    >
                                      {record?.musicTitle}
                                    </span>

                                  </td>
                                  <td className="text-left cursor-pointer">
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                                      <span
                                        className={`font-size-16 cursor-pointer tracking-normal font-medium ${highlight
                                            ? highlight === index &&
                                            "white-text-color"
                                            : index === 0 && "white-text-color"
                                          }`}
                                        onClick={() => {
                                          history(
                                            `/profile/${record.artistId._id}`
                                          );
                                        }}
                                        OnTouchStart={() => {
                                          history(
                                            `/profile/${record.artistId._id}`
                                          );
                                        }}
                                      >

                                        {record?.artistId.artistName}{" "}
                                        {/* {record?.artistId.realName} */}

                                      </span>

                                      <div className="cursor-pointer pr-5">
                                        {/* <div className="pr-5"> */}
                                        <span
                                          className={`font-size-16 font-medium tracking-normal  ${highlight
                                              ? highlight === index &&
                                              "white-text-color"
                                              : index === 0 && "white-text-color"
                                            }`}
                                        >
                                        </span>
                                      </div>
                                      <div className="My_Dropdown ">
                                        {(() => {
                                          if (authUtil.isLoggedIn()) {
                                            return record.isPaid ? (
                                              userUtil
                                                ?.getMusicData()
                                                ?.includes(record._id) ? (
                                                <Dropdown show={index === selectedSongIndex} //Code ya leo
                                                  onMouseEnter={() => {
                                                    setSelectedSongIndex(index)
                                                  }}
                                                  onMouseLeave={() => {
                                                    setSelectedSongIndex()
                                                  }}
                                                >
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="text-white"
                                                  >
                                                    <Tooltip title="More" arrow>
                                                      <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                                                    </Tooltip>
                                                  </Dropdown.Toggle>
                                                  {index === selectedSongIndex && (<Dropdown.Menu>
                                                    <div
                                                      className="flex flex-col w-32 max-w-full box-shadow-cus border-color"
                                                      style={{
                                                        backgroundColor: "#090C10",
                                                      }}
                                                    >
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() =>
                                                          authUtil.isLoggedIn()
                                                            ? setPlaylistModal({
                                                              musicId: record._id,
                                                              open: true,
                                                            })
                                                            : setLoginModal(true)
                                                        }

                                                      >
                                                        Add to playlist
                                                      </div>

                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() =>
                                                          dispatch(getMusicData(record))
                                                        }

                                                      >
                                                        Play song now
                                                      </div>
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() =>
                                                          history(`/song/${record._id}`)
                                                        }

                                                      >
                                                        Details
                                                      </div>
                                                      {!record.isPaid && (
                                                        <div
                                                          className="text-white p-2 cursor-pointer"
                                                          onClick={() => {
                                                            setDownloadId(record._id);
                                                            console.log(
                                                              "Download Onclick",
                                                              record._id
                                                            );
                                                            authUtil.isLoggedIn()
                                                              ? handledownload(record)
                                                              : setLoginModal(true);
                                                          }}

                                                        >
                                                          Download
                                                        </div>
                                                      )}
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() => {
                                                          shareLink(record._id);
                                                        }}

                                                      >
                                                        <button onClick={copy}
                                                        >
                                                          {!copied
                                                            ? "Copy link"
                                                            : "Copy link"}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Menu>)}
                                                </Dropdown>
                                              ) : (
                                                <div
                                                  onClick={() =>
                                                    handlePlayplaylistData(record, index)         // play all free songs on a list one by one from page to page 
                                                  }
                                                >
                                                  <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                                </div>
                                              )
                                            ) : (
                                              <Dropdown show={index === selectedSongIndex} //Code ya leo
                                                onMouseEnter={() => {
                                                  setSelectedSongIndex(index)
                                                }}
                                                onMouseLeave={() => {
                                                  setSelectedSongIndex()
                                                }}
                                              >
                                                <Dropdown.Toggle
                                                  variant="success"
                                                  id="dropdown-basic"
                                                  className="text-white"
                                                >
                                                  <Tooltip title="More" arrow>
                                                    <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                                                  </Tooltip>{" "}
                                                </Dropdown.Toggle>

                                                {index === selectedSongIndex && (<Dropdown.Menu>
                                                  <div
                                                    className="flex flex-col w-32 max-w-full box-shadow-cus border-color"
                                                    style={{
                                                      backgroundColor: "#090C10",
                                                    }}
                                                  >
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        authUtil.isLoggedIn()
                                                          ? setPlaylistModal({
                                                            musicId: record._id,
                                                            open: true,
                                                          })
                                                          : setLoginModal(true)
                                                      }

                                                    >
                                                      Add to playlist
                                                    </div>

                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        dispatch(getMusicData(record))
                                                      }

                                                    >
                                                      Play song now
                                                    </div>
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        history(`/song/${record._id}`)
                                                      }

                                                    >
                                                      Details
                                                    </div>
                                                    {!record.isPaid && (
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() => {
                                                          setDownloadId(record._id);
                                                          console.log(
                                                            "Download Onclick",
                                                            record._id
                                                          );
                                                          authUtil.isLoggedIn()
                                                            ? handledownload(record)
                                                            : setLoginModal(true);
                                                        }}

                                                      >
                                                        Download
                                                      </div>
                                                    )}
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() => {
                                                        shareLink(record._id);
                                                      }}

                                                    >
                                                      <button onClick={copy}
                                                      >
                                                        {!copied
                                                          ? "Copy link"
                                                          : "Copy link"}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </Dropdown.Menu>)}
                                              </Dropdown>
                                            );
                                          }
                                          else {
                                            // (authUtil.isLoggedIn())  // problem is that if this line is uncommented no dropdown will display while user is not ;ogged in
                                            return record.istPaid ? (
                                              userUtil
                                                ?.getMusicData()
                                                ?.includes(record._id) ? (
                                                <Dropdown show={index === selectedSongIndex} //Code ya leo
                                                  onMouseEnter={() => {
                                                    setSelectedSongIndex(index)
                                                  }}
                                                  onMouseLeave={() => {
                                                    setSelectedSongIndex()
                                                  }}
                                                >
                                                  <Dropdown.Toggle
                                                    variant="success"
                                                    id="dropdown-basic"
                                                    className="text-white"
                                                  >
                                                    <Tooltip title="More" arrow>
                                                      <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                                                    </Tooltip>
                                                  </Dropdown.Toggle>
                                                  {index === selectedSongIndex && (<Dropdown.Menu>
                                                    <div
                                                      className="flex flex-col w-32 max-w-full box-shadow-cus border-color"
                                                      style={{
                                                        backgroundColor: "#090C10",
                                                      }}
                                                    >
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() =>
                                                          authUtil.isLoggedIn()
                                                            ? setPlaylistModal({
                                                              musicId: record._id,
                                                              open: true,
                                                            })
                                                            : setLoginModal(true)
                                                        }

                                                      >
                                                        Add to playlist
                                                      </div>

                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() =>
                                                          dispatch(getMusicData(record))
                                                        }

                                                      >
                                                        Play song now
                                                      </div>
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() =>
                                                          history(`/song/${record._id}`)
                                                        }

                                                      >
                                                        Details
                                                      </div>
                                                      {!record.isPaid && (
                                                        <div
                                                          className="text-white p-2 cursor-pointer"
                                                          onClick={() => {
                                                            setDownloadId(record._id);
                                                            console.log(
                                                              "Download Onclick",
                                                              record._id
                                                            );
                                                            authUtil.isLoggedIn()
                                                              ? handledownload(record)
                                                              : setLoginModal(true);
                                                          }}

                                                        >
                                                          Download
                                                        </div>
                                                      )}
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() => {
                                                          shareLink(record._id);
                                                        }}

                                                      >
                                                        <button onClick={copy}
                                                        >
                                                          {!copied
                                                            ? "Copy link"
                                                            : "Copy link"}
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </Dropdown.Menu>)}
                                                </Dropdown>
                                              ) : (
                                                <div
                                                  onClick={() =>
                                                    handlePlayplaylistData(record, index)         // play all free songs on a list one by one from page to page 

                                                  }
                                                >
                                                  <i className="fas fa-crown yellow-icon-color cursor-pointer font-size-18"></i>
                                                </div>
                                              )
                                            ) : (
                                              <Dropdown show={index === selectedSongIndex} //Code ya le 20 September 2023
                                                onMouseEnter={() => {
                                                  setSelectedSongIndex(index)
                                                }}
                                                onMouseLeave={() => {
                                                  setSelectedSongIndex()
                                                }}
                                              >
                                                <Dropdown.Toggle
                                                  variant="success"
                                                  id="dropdown-basic"
                                                  className="text-white"
                                                >
                                                  <Tooltip title="More" arrow>
                                                    <i className="fas fa-ellipsis-v cursor-pointer font-size-10 text-white p-3"></i>
                                                  </Tooltip>{" "}
                                                </Dropdown.Toggle>

                                                {index === selectedSongIndex && (<Dropdown.Menu>
                                                  <div
                                                    className="flex flex-col w-32 max-w-full box-shadow-cus border-color"
                                                    style={{
                                                      backgroundColor: "#090C10",
                                                    }}
                                                  >
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        authUtil.isLoggedIn()
                                                          ? setPlaylistModal({
                                                            musicId: record._id,
                                                            open: true,
                                                          })
                                                          : setLoginModal(true)
                                                      }

                                                    >
                                                      Add to playlist
                                                    </div>

                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        dispatch(getMusicData(record))
                                                      }
                                                    >
                                                      Play song now
                                                    </div>
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() =>
                                                        history(`/song/${record._id}`)
                                                      }

                                                    >
                                                      Details
                                                    </div>
                                                    {!record.isPaid && (
                                                      <div
                                                        className="text-white p-2 cursor-pointer"
                                                        onClick={() => {
                                                          setDownloadId(record._id);
                                                          console.log(
                                                            "Download Onclick",
                                                            record._id
                                                          );
                                                          authUtil.isLoggedIn()
                                                            ? handledownload(record)
                                                            : setLoginModal(true);
                                                        }}

                                                      >
                                                        Download
                                                      </div>
                                                    )}
                                                    <div
                                                      className="text-white p-2 cursor-pointer"
                                                      onClick={() => {
                                                        shareLink(record._id);
                                                      }}

                                                    >
                                                      <button onClick={copy}
                                                      >
                                                        {!copied
                                                          ? "Copy link"
                                                          : "Copy link"}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </Dropdown.Menu>)}
                                              </Dropdown>
                                            );
                                          }
                                        }
                                        )()}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <div className="text-white" align="center">
                              NO DATA FOUND <i className="far fa-frown-open"></i>
                            </div>

                          )
                          }

                        </tbody>
                      </table>

                    </div>
                  </div>
                </div>
                {loading && <LoadingBalls />}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
