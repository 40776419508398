/* jshint esversion: 6 */
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import { ApiGet } from "../../helpers/API/ApiData";
import renderHTML from "react-render-html";
import CookieConsent from "react-cookie-consent";

export default function Privacypolicy() {
  const [loading, setLoading] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getAllFaq = async () => {
      try {
        setLoading(true);
        const res = await ApiGet("privacy/get-all-privacy");
        if (res.status === 200) {
          setFaqData(res.data.data);
        } else {
          console.error("Error fetching privacy data.");
        }
      } catch (err) {
        console.error("API Error:", err);
      } finally {
        setLoading(false);
      }
    };
    getAllFaq();
  }, []);

  return (
    <div>
      <Header />
      <div className="flex">
        <Sidebar />
        <main className="s-layout__content">
          {loading ? (
            <center>
              <div className="mt-36">
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </center>
          ) : faqData.length > 0 ? (
            faqData.slice(0).reverse().map((data, index) => (
              <div key={index} className="rounded-3xl pt-24 pb-6">
                <div className="rounded-3xl pl-10 pr-10">
                  <h2 className="adobe-font font-size-25 green-text-color tracking-normal pb-2">
                    Privacy and Policies
                  </h2>
                </div>
                {data?.content.map((content, subIndex) => (
                  <div key={subIndex} className="pl-10 pr-10 rounded-3xl">
                    <p className="adobe-font font-size-20 green-text-color font-medium tracking-normal text-left pb-2 rounded-3xl">
                      {content?.title}
                    </p>
                    <p className="font-size-18 white-text-color font-medium tracking-normal text-left pb-8 rounded-3xl">
                      {content?.description ? renderHTML(content.description) : ""}
                    </p>
                  </div>
                ))}
              </div>
            ))
          ) : null}

          <CookieConsent
            flipButtons
            location="bottom"
            buttonText="Accept All Cookies"
            cookieName="myAwesomeCookieName2"
            style={{
              background: "#0f0f0f",
              padding: "5px",
              color: "#fff",
              borderRadius: "3px",
              textAlign: "left",
              width: "415px",
            }}
            buttonStyle={{
              borderRadius: "3px",
              background: "#000000",
              color: "#fff",
              fontSize: "16px",
              padding: "10px 20px",
              border: "none",
              cursor: "pointer",
            }}
            expires={150}
          >
            <h3 className="font-bold text-lg">Cookies Notice</h3>
            <p className="text-sm mt-2">
              We use cookies to enhance your browsing experience,<br></br> serve personalized ads or content,
              and analyze our<br></br> traffic. By clicking <strong>"Accept All Cookies,"</strong> you agree
              to <br></br>our use of cookies. You can manage your cookie <br></br> preferences through the
              <strong> "Settings"</strong> option.
             <h3>
              For more details on how we manage your data, see our <br></br>
              <NavLink
                to="/privacypolicy"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}
                className="pl-2 font-semibold text-green-400 hover:text-green-300 rounded-3xl"
              >
                Privacy Policy
              </NavLink>.
              </h3>
            </p>
          </CookieConsent>
        </main>
      </div>
    </div>
  );
}
